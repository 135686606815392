.root {
  transform: rotateZ(45deg) scale(var(--scale, 1));
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  user-select: none;
  color: #fff;
  display: block;
  transform-origin: center;
  --scale: 1;
}
.root:before,
.root:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 0.85s spin ease-in-out infinite;
}
.root:after {
  color: #87daf9;
  transform: rotateY(70deg);
  /*animation-delay: 0.4s;*/
  animation: 0.85s spin2 ease-in-out infinite;
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
@keyframes spin2 {
  0%,
  100% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  12% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  37% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  62% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  87% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
}

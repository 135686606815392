.coverPreview {
  max-width: calc(320px - 6px);
  width: 100%;
  display: block;
  aspect-ratio: 306/180;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none;
  border-radius: 6px;
  margin-top: 12px;
}
.coverPreview:has(.defaultCover) {
  background-color: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultCover {
  height: 15%;
  width: 15%;
  max-width: 70px;
  max-height: 70px;
  min-width: 30px;
  min-height: 30px;
  color: #a0a0a0;
}
.screenshotManager {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  position: relative;
  width: 100%;
  margin-top: 12px;
}
.screenshotManagerItem {
  width: 100%;
  display: grid;
  padding: 10px;
  height: 60px;
  position: relative;
  column-gap: 12px;
  grid-template-columns: 40px 70px 1fr 40px;
  border-radius: 6px;
  border: 1px solid var(--gray-dark-5, #2e2e2e);
  background: var(--gray-dark-3, #232323);
}
.screenshotManagerItem > figure,
.screenshotManagerItem > button {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #a0a0a0;
  transition: all 0.2s ease-in-out;
}
.screenshotManagerItem > figure > svg,
.screenshotManagerItem > button > svg {
  height: 20px;
  width: 20px;
}
.screenshotManagerItem > figure:hover {
  cursor: row-resize;
  background-color: #282828;
  color: #ededed;
}
.screenshotManagerItem > button:hover {
  cursor: pointer;
  background-color: #431d16;
  color: #e07258;
}
.screenshotManagerItem > picture {
  height: 40px;
  border-radius: 6px;
  background-color: #1c1c1c;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.screenshotManagerItem > figcaption {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  align-items: center;
  color: var(--gray-dark-12, #ededed);
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.previewGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  transition: all 0.2s ease-in-out;
}
.previewGridDisabled {
  opacity: 0.75;
  pointer-events: none;
}
.previewItem {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: #232323;
  position: relative;
  border-radius: 6px;
  padding: 10px;
}
.previewItem > picture {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background-color: #232323;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.previewItemOverlay {
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  z-index: 5;
}
.previewItem > picture:hover > .previewItemOverlay {
  opacity: 1;
  pointer-events: all;
}
.previewItemAdd {
  padding: 0;
  gap: 10px;
}
.previewItemAdd > span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
}
.previewItemYoutube {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.previewItemYoutube > svg {
  color: #ea3323;
  height: 70px;
  width: 70px;
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}
.previewItem > picture:hover .previewItemYoutube > svg {
  opacity: 0;
}
.previewUndo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  gap: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  transition: all 0.2s ease-in-out;
}
.previewUndo:not(:disabled):hover {
  color: rgba(255, 255, 255, 0.8);
}
.previewUndo:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.previewUndo > svg {
  height: 18px;
  width: 18px;
}

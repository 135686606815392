.root {
  display: flex;
  position: relative;
  height: 40px;
  background: #282828;
  border: 2px solid #343434;
  border-radius: 6px;
  color: #ededed;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.rootDisabled {
  cursor: not-allowed;
  background-color: #232323;
  border-color: transparent;
  color: #707070;
}
.root .icon {
  height: 20px;
  width: 20px;
  margin-left: 8px;
  color: inherit;
  min-width: 20px;
}
.root .icon > svg {
  height: 100%;
  width: 100%;
}
.root .rightIcon {
  position: absolute;
  height: 20px;
  width: 20px;
  pointer-events: none;
  right: 10px;
  z-index: 50;
}
.root .rightIcon > svg {
  height: 100%;
  width: 100%;
}
.root input,
.root textarea {
  height: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0 10px;
}
.root textarea {
  padding: 7px 10px;
}
.root:not(.rootDisabled):hover {
  background: #282828;
  border: 2px solid #505050;
}
.root:not(.rootDisabled):has(input:focus),
.root:not(.rootDisabled):has(textarea:focus) {
  background: #282828;
  border: 2px solid #005d85;
}
.counter {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  user-select: none;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 50;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
  bottom: 11px;
}
.counterShift {
  right: 40px;
}
.rootTextArea {
  height: 140px;
}
.rootTextArea > textarea {
  resize: none;
}

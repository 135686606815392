.header {
  gap: 24px;
}
.header::before {
  background-image: url(../../assets/images/userIcon.png);
}
.headerIcon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: inset 0px 0px 0px 3px var(--theme, #a0a0a0);
}
.headerIcon > picture {
  height: 86px;
  width: 86px;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #292929;
  border-radius: 50%;
}
.headerInfo {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}
.headerInfoName {
  color: var(--gray-dark-12, #ededed);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}
.headerInfoEmail {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.group {
  display: grid;
  grid-template-columns: minmax(220px, 1fr) 2.5fr minmax(220px, 1fr);
  width: 100%;
  position: relative;
  margin: 32px 0;
}
.groupTitle {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.groupActions {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;
}
.groupContent {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 12px;
}
.divider {
  display: block;
  width: 100%;
  background: #282828;
  height: 1px;
  flex-shrink: 0;
}
.groupInput {
  width: 100%;
}
.avatar {
  gap: 20px;
}
.avatar > picture {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  display: block;
}
.avatar > figcaption {
  color: var(--gray-dark-12, #ededed);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.avatarEditor {
  margin-top: 16px;
  padding: 16px;
  justify-content: space-between;
  width: 100%;
  border-radius: 7px;
  border: 2px dashed var(--gray-dark-4, #282828);
  background: rgba(255, 255, 255, 0);
}
.avatarInfo {
  gap: 12px;
}
.avatarInfo > picture {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--gray-dark-4, #282828);
  box-shadow: 0px 1.1px 2.2px 0px rgba(16, 24, 40, 0.05);
}
.avatarInfo > picture > svg {
  height: 28px;
  width: 28px;
  color: #a0a0a0;
}
.avatarInfoText {
  gap: 8px;
  flex-flow: column nowrap;
  display: flex;
}
.avatarInfoTextTitle {
  color: var(--White, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.avatarInfoTextTagline {
  color: var(--gray-dark-9, #707070);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.actions {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.emailWarning {
  width: 100%;
  justify-content: space-between;
  height: 40px;
  position: relative;
  gap: 12px;
}
.emailWarning > button {
  flex-shrink: 0;
}
.emailWarning > figure {
  flex-grow: 1;
  height: 100%;
  padding: 11px;
  color: var(--tomato-dark-11, #f16a50);
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  border-radius: 6px;
  background: var(--tomato-dark-2, #2a1410);
  user-select: none;
}
.emailWarning > figure > svg {
  height: 18px;
  width: 18px;
}
.groupInput > figure:has(.emailVerified) {
  pointer-events: auto;
  cursor: pointer;
}
.emailVerified {
  color: #99d52a;
}
.groupInput > figure:has(.emailVerified)::before,
.groupInput > figure:has(.emailVerified)::after {
  position: absolute;
  left: 50%;
  display: flex;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.36, 0, 0.66, -0.56);
  background: var(--lime-dark-3, #1e260d);
}
.groupInput > figure:has(.emailVerified)::before {
  content: 'Verified';
  bottom: calc(20px + 12px + 5px);
  padding: 6px 12px;
  transform: translateX(-50%) scale(0.8);
  border-radius: 6px;
  color: var(--lime-dark-9, #99d52a);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  z-index: 5;
}
.groupInput > figure:has(.emailVerified)::after {
  content: '';
  transform-origin: center;
  bottom: calc(20px + 12px);
  transform: translateX(-50%) rotate(45deg) scale(0.8);
  height: 16px;
  width: 16px;
  border-radius: 2px;
  z-index: 4;
}
.groupInput > figure:has(.emailVerified):hover::before,
.groupInput > figure:has(.emailVerified):hover::after {
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.groupInput > figure:has(.emailVerified):hover::before {
  transform: translateX(-50%) scale(1);
}
.groupInput > figure:has(.emailVerified):hover::after {
  transform: translateX(-50%) rotate(45deg) scale(1);
}

.rootLoader {
  height: calc(100vh - 72px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
/*.header {
  height: 216px;
  display: flex;
  padding: 0 var(--gutter);
  width: 100%;
  flex-flow: column nowrap;
  position: sticky;
  padding: 40px var(--gutter);
  top: 0;
  z-index: 200;
  background-color: #161616;
}
.header .title {
  font-size: 56px;
  line-height: 56px;
  color: #ededed;
}
.headerTools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 32px;
}*/
body .grid {
  /*margin: 0 var(--gutter);
  width: calc(100% - var(--gutter-double));
  margin-top: 10px;
  margin-bottom: 300px;*/
  /*margin-top: calc(144px - 32px);*/
  width: calc(100% - var(--gutter) - 32px);
  margin: 0 calc(var(--gutter));
  margin-top: 10px;
  margin-left: calc(32px);
  padding-bottom: 32px;
}
/*.headerRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
}
*/
.formError {
  color: #e54d2ef2;
  margin-left: 6px;
  gap: 4px;
}
.formError > svg {
  height: 16px;
  width: 16px;
}

/*.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #ededed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}*/
/*
.formGroup {
  gap: 8px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
  max-width: 640px;
}
.section {
  gap: 28px;
  padding: 32px 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.divider {
  height: 1px;
  width: 100%;
  display: block;
  background-color: #282828;
}
.formGroupLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #a0a0a0;
}
.formGroupInput {
  width: 100%;
}*/
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.headerTools {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
}
/*.sectionTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ededed;
}*/
/*.toggleGroup {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
}
.toggleGroupLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
  color: #ededed;
}
*/

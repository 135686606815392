.root {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  height: auto;
  align-items: center;
}
.image {
  aspect-ratio: 1/1;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1c1c;
  border-radius: 6px;
  transition: all 0.2s ease-in;
}
.image > picture {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
}
.title {
  padding: 0 10px;
  margin-top: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.background,
.overlay {
  height: calc(100% + 20px + 5px);
  width: calc(100% + 20px);
  position: absolute;
  top: -10px;
  left: -10px;
  border-radius: 6px;
  opacity: 0;
  transition: all 0.2s ease-in;
  pointer-events: none;
  user-select: none;
}
.background {
  z-index: -1;
  background-color: #1c1c1c;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
}
.overlay {
  z-index: 100;
  cursor: pointer;
}
.rootActive {
  z-index: 10;
}
.rootActive .background,
.rootActive .overlay {
  opacity: 1;
}
.rootActive .overlay {
  pointer-events: auto;
}
.rootActive .image {
  background-color: #262626;
}
.price {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #ededed;
  gap: 8px;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.2px;
  border-radius: 5px;
  background: var(--gray-dark-2, #1c1c1cb0);
  backdrop-filter: blur(10px);
  padding: 2px 4px;
  width: fit-content;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-transform: uppercase;
}
.price > svg {
  height: 20px;
  width: 20px;
}
.defaultIcon {
  height: 15%;
  width: 15%;
  max-width: 70px;
  max-height: 70px;
  min-width: 30px;
  min-height: 30px;
  color: #a0a0a0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  grid-gap: 30px;
  width: 100%;
  margin-top: 20px;
}

.yellow {
  --tag-bg: #f0da4a;
  --tag-color: #161616;
  --tag-bg-hover: #fcef75;
  --tag-color-hover: #1b1502;
}
.blue {
  --tag-bg: #00b4ff;
  --tag-color: #161616;
  --tag-bg-hover: #00d4ff;
  --tag-color-hover: #161616;
}

/*.root {
  display: grid;
  height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
  grid-template-columns: calc(300px - 32px) 1fr;
  column-gap: 52px;
  margin: 0 var(--gutter);
  width: calc(100% - var(--gutter-double));
  position: fixed;
  overflow: hidden;
  top: 72px;
}
.rootLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aside {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-right: 32px;
  border-right: 1px solid #282828;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 40px;
}
.main {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
}*/
.worldPreview {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #2e2e2e;
}
.worldIcon {
  width: 96px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  user-select: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.worldIcon:has(.defaultIcon) {
  background-color: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultIcon {
  height: 22px;
  width: 22px;
  color: #a0a0a0;
}
.worldDetails {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  overflow: hidden;
}
.worldTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #ededed;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3px;
}
.worldVisibility {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  user-select: none;
  color: #87be22;
}
.worldVisibilityPrivate {
  color: #a0a0a0;
}
/*.nav {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  position: relative;
}
.navLink {
  padding: 0 8px;
  height: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  transition: all 0.2s ease-in-out;
}
.navLinkActive {
  color: #161616;
  background: #ededed;
  cursor: default;
}
.navLink:not(.navLinkActive):hover {
  color: #ededed;
  background-color: #282828;
}
*/
.floatButton {
  position: absolute;
  bottom: 32px;
  width: calc(100% - 32px - var(--gutter));
}
.saveButton {
  position: relative;
  padding: 0 10px;
}
.saveButton > span {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: inherit;
  font: inherit;
  line-height: inherit;
  transform-origin: center;
  transition: all 0.2s ease-in-out;
}
.saveButton:disabled > span:not(.buttonLoader) {
  transform: scale(0.85);
  opacity: 0;
  pointer-events: none;
}
.buttonLoader {
  position: absolute;
}

.header {
  justify-content: space-between;
}
.header::before {
  background-image: url(../../assets/images/walletIcon.png);
}
.headerGroup {
  display: flex;
  flex-flow: column nowrap;
  gap: 18px;
}
.headerTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px; /* 100% */
  gap: 12px;
}
.headerTitle > svg {
  height: 28px;
  width: 28px;
}
.headerText {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.headerTag {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  opacity: 0.5;
}
.head {
  width: 100%;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 32px;
}
.head > div {
  gap: 12px;
}
.headTitle {
  gap: 10px;
}
.tableAmount {
  gap: 8px;
}
.tableAmount > svg {
  height: 20px;
  width: 20px;
}
.tableName {
  gap: 12px;
}
.tableName > picture {
  height: 32px;
  width: 32px;
  background-size: cover;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
}
.tableName > figure {
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #232323;
  color: #c0c0c0;
}
.tableName > figure > svg {
  height: 18px;
  width: 18px;
}

.root:has(.rootLoader),
.root:has(.rootError) {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.root {
  padding-bottom: 12px;
}
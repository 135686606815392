div:has(.root) {
  margin-top: 0 !important;
}

.root {
  height: 100vh;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: minmax(520px, 1fr) 1fr;
}
.bg {
  height: 100%;
  background-size: cover;
  background-image: url(../../assets/images/astronaut.png);
  background-repeat: no-repeat;
  background-position: center;
}

.wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 26px 1fr 20px;
  padding: 32px;
  gap: 32px;
  position: relative;
}
.header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.header > svg {
  height: 26px;
}
.footer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--gray-dark-12, #ededed);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  width: 100%;
}
.frameWrapper {
  width: 480px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  max-width: 480px;
  --width: 480px;
  overflow: hidden;
}
.frameTrack {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transform: translateX(0);
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}
.frame {
  width: var(--width);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  max-width: var(--width);
  transition: opacity 0.4s cubic-bezier(0.85, 0, 0.15, 1);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.frameActive {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s cubic-bezier(0.85, 0, 0.15, 1);
  pointer-events: auto;
}
.title {
  color: var(--gray-dark-12, #ededed);
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 115% */
}
.subtitle {
  margin-top: 16px;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.icon {
  border-radius: 100%;
  background: var(--sky-dark-4, #082d41);
  height: 88px;
  width: 88px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.icon > svg {
  color: #2ec8ee;
  height: 48px;
  transform-origin: center;
  width: 48px;
}
.form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 32px;
  gap: 14px;
}
.button {
  margin-top: 32px;
  width: 100%;
}
@media screen and (max-width: 1190px) {
  .frameWrapper {
    width: 460px;
    --width: 460px;
    max-width: 460px;
  }
}
@media screen and (max-width: 1090px) {
  .frameWrapper {
    width: 440px;
    --width: 440px;
    max-width: 440px;
  }
}
.codeInput {
  width: 100%;
  height: calc(80px + 66px);
  /*background-color: #232323;*/
  margin-top: 32px;
  padding: 30px 0;
  border-radius: 9px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-self: center;
  display: grid;
  grid-template-columns: 2fr calc(60px * 3) 3fr calc(60px * 3) 2fr;
  box-shadow: inset 0 0 0 1px #282828;
}
.codeDivider {
  height: 1px;
  background-color: #282828;
} /*
.codeDivider:first-child::before,
.codeDivider:last-child::after {
  content: '';
  position: absolute;
  height: 80%;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #282828;
  left: 0;
}
.codeDivider:last-child::after {
  right: 0;
  left: auto;
}*/
.codeInput > span {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.codeInput > span > input {
  height: 100%;
  font-family: Space Mono;
  width: 100%;
  font-weight: 900;
  font-size: 28px;
  line-height: 38px;
  padding-bottom: 6px;
  width: 60px;
  flex-shrink: 0;
  color: #ededed;
  text-align: center;
  border: 1px solid #282828;
  background-color: #1c1c1c;
  border-left: none;
  transition: box-shadow 0.08s ease-in;
}
.codeInput > span > input:first-child {
  border-radius: 6px 0 0 6px;
  border-left: 1px solid #282828;
}
.codeInput > span > input:last-child {
  border-radius: 0 6px 6px 0;
}
.codeInput > span > input:not(:disabled):focus {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
    0 0 0 4px rgba(50, 151, 211, 0.3);
  z-index: 10;
}
.codeInput > span > input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  user-select: none;
}
.buttonError {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 7px;
  color: #ca5639;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 12px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-4px);
  transition: all 0.3s ease-in-out;
}
.buttonError > svg {
  height: 18px;
  width: 18px;
}
.buttonErrorActive {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
@media screen and (max-width: 950px) {
  .root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper {
    width: 100%;
    z-index: 10;
    background-color: #00000083;
    backdrop-filter: blur(10px);
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.5);
  }
  .frameWrapper {
    width: 540px;
    --width: 540px;
    max-width: 540px;
  }
  .codeInput {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 0 0 1px #7a7a7a;
  }
  .codeInput > span > input,
  .codeInput > span > input:first-child,
  .codeInput > span > input:last-child {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: #7a7a7a;
  }
  .codeDivider {
    background-color: #7a7a7a;
  }
}

@media screen and (max-width: 700px) {
  .frameWrapper {
    width: 460px;
    --width: 460px;
    max-width: 460px;
  }
}
@media screen and (max-width: 550px) {
  .frameWrapper {
    width: calc(100vw - 32px * 2);
    --width: calc(100vw - 32px * 2);
    max-width: calc(100vw - 32px * 2);
  }
}
@media screen and (max-width: 530px) {
  .root {
    height: auto;
    justify-content: flex-start;
  }
  .wrapper {
    height: 100%;
    gap: 64px;
  }
  .codeInput {
    height: calc(60px + 66px);
    grid-template-columns: 2fr calc(50px * 3) 3fr calc(50px * 3) 2fr;
  }
  .codeInput > span > input {
    width: 50px;
  }
}
@media screen and (max-width: 480px) {
  .codeInput {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 4px;
    background-color: transparent;
    box-shadow: none;
    position: relative;
  }
  .codeDivider:first-child,
  .codeDivider:last-child {
    display: none;
  }
  .codeDivider {
    width: 1px;
    height: 32px;
    flex-shrink: 0;
    display: none;
  }
  .codeInput > span {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    flex-shrink: 0;
    position: relative;
    height: 90px;
    flex-grow: 0;
  }
  .codeInput > span > input {
    width: 100%;
  }
  .codeInput > span:first-of-type > input:last-child,
  .codeInput > span:last-of-type > input:first-child {
    border-radius: 0;
  }
  .codeInput > span:last-of-type > input:first-child {
    border-left: 0;
  }
}
@media screen and (max-width: 420px) {
  .codeInput {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
  }
  .codeInput > span:first-of-type > input:last-child {
    border-radius: 0 6px 6px 0;
  }
  .codeInput > span:last-of-type > input:first-child {
    border-radius: 6px 0 0 6px;
    border-left: 1px solid #7a7a7a;
  }
}
.disclaimer {
  width: 100%;
  gap: 8px;
  margin-top: 0px;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
.disclaimer > a {
  text-decoration: none;
  cursor: pointer;
  color: var(--gray-dark-11, #a0a0a0);
}
.disclaimer > a:hover {
  text-decoration: underline;
}
.root {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: center;
  height: calc(100vh);
}
div:has(.root) {
  margin-top: 0 !important;
}
.wrapper {
  max-width: 500px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}

.wrapper > button {
  width: 100%;
  margin-top: 18px;
}
.title {
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  color: rgb(237, 237, 237);
  text-align: left;
  align-self: flex-start;
  margin-bottom: 1em;
}
.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgb(171, 171, 171);
  text-align: left;
  margin-top: 20px;
  align-self: flex-start;
}
.successIcon {
  height: 88px;
  width: 88px;
  margin-bottom: 28px;
  flex-shrink: 0;
  background: var(--sky-dark-4, #084112);
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  align-self: flex-start;
}
.successIcon > svg {
  color: #2eee3e;
  height: 48px;
  width: 48px;
}

.statsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
  position: relative;
}
.statsGroup {
  height: 62px;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  position: relative;
  gap: 8px;
  border-radius: 6px;
  background: #1c1c1c;
}
.statsGroupTitle {
  font-weight: 500;
  text-transform: uppercase;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
  font-size: 12px;
}
.statsGroupValue {
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.statsGroupValue > svg {
  height: 20px;
  width: 20px;
}
.tableAmount {
  gap: 8px;
}
.tableAmount > svg {
  height: 20px;
  width: 20px;
}
.footerRow {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}
.root:has(.rootLoader),
.root:has(.rootError) {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.statsLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  flex-grow: 0;
}
.statsLoader > span {
  position: absolute;
}
.live {
  height: 12px;
  width: 12px;
  background-color: #ff412b;
  border-radius: 50%;
  user-select: none;
  animation: live 2.5s ease-out infinite;
  opacity: 0.85;
}
@keyframes live {
  from,
  50% {
    opacity: 0.85;
  }
  from,
  70% {
    box-shadow: 0 0 0 0 rgba(255, 65, 43, 0.7);
  }
  75% {
    opacity: 0.5;
  }
  to {
    box-shadow: 0 0 0 8px rgba(255, 65, 43, 0);
    opacity: 0.85;
  }
}

div:has(.root) {
  margin-top: 0 !important;
}
.root {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-flow: column nowrap;
}

.loader {
  height: 100vh;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #161616;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  justify-content: center;
}
.loaderShow {
  opacity: 1;
  pointer-events: auto;
}
.wrapper {
  max-width: 560px;
  width: calc(100% - 32px - 32px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;
  transform-origin: center;
  transition: all 0.2s ease-in-out;
}
.wrapperHide {
  transform: scale(1.05);
  transition: opacity 0.2s ease-in-out, transform 2s ease-in-out;
  opacity: 0;
}
.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 43px;
  color: rgb(237, 237, 237);
  max-width: 350px;
}
.form {
  margin-top: 18px;
  width: 100%;
  position: relative;
  gap: 14px;
  display: flex;
  flex-flow: column nowrap;
}
.forgotLink {
  text-decoration: none;
  color: rgb(160, 160, 160);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 28px;
  transition: all 0.2s ease-in-out;
}
.forgotLink:hover {
  color: rgb(255, 255, 255);
}

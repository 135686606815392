.wrapper {
  grid-template-columns: 1fr;
}
.root {
  position: relative;
  padding: 32px var(--gutter);
  gap: 20px;
  grid-template-rows: 40px 1fr;
  display: grid;
  max-height: calc(100vh - 72px);
}
.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 8px;
  height: 40px;
}
.header > svg {
  height: 24px;
  width: 24px;
  margin: 0 6px;
}
.headerTools {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
}
.headerButton {
  padding: 0 10px;
}
.header > span {
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  user-select: none;
  color: #7a7a7a;
}
.header > div {
  font-family: Roboto Mono;
  font-size: 15px;
  font-weight: 400;
  color: #cecece;
  cursor: copy;
}
.header > div > b {
  color: #03ffd1;
}
.console {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  white-space: nowrap;
  overflow: auto;
  font-family: Roboto Mono;
}
.line {
  width: 100%;
  display: inline-block;
  flex-shrink: 0;
  font-size: 14px;
  color: rgb(237, 237, 237);
  line-height: 21px;
}
.line:hover {
  background-color: #282828;
}
.lineError {
  color: rgb(255, 97, 102);
}
.lineError:hover {
  background-color: rgb(86, 26, 30);
}
.lineWarning {
  color: rgb(255, 193, 7);
}
.lineWarning:hover {
  background-color: rgb(86, 61, 26);
}
/* debug should be brown */
.lineDebug {
  color: rgb(255, 193, 7);
}
.lineDebug:hover {
  background-color: rgb(86, 61, 26);
}
/* info should be aqua blue */
.lineInfo {
  color: rgb(3, 255, 209);
}
.lineInfo:hover {
  background-color: rgb(26, 86, 61);
}
.input {
  position: fixed;
  bottom: 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  color: rgb(237, 237, 237);
  line-height: 21px;
  font-family: Roboto Mono;
  height: 22px;
  width: calc(100% - var(--gutter-double));
}
.input > .spacer {
  opacity: 0;
  user-select: none;
  pointer-events: none;
  flex-shrink: 0;
}
.input > .caret {
  color: #03ffd1;
  user-select: none;
  flex-shrink: 0;
}
.input > .inputContainer {
  flex-grow: 1;
  height: 22px;
  position: relative;
}
.input > .inputContainer > input {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.input > .inputContainer > .shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: -1;
}
.input > .inputContainer > .shadow > .coloring > span {
  gap: 8px;
  display: inline-flex;
  flex-flow: row nowrap;
}
.input > .inputContainer > .shadow > .coloring {
  position: absolute;
  left: 0;
  user-select: none;
  pointer-events: none;
  gap: 8px;
  display: flex;
  flex-flow: row nowrap;
}
.input > .inputContainer > .shadow > .coloring > .spacer {
  height: 100%;
  width: 0;
  flex-shrink: 0;
  pointer-events: none;
  user-select: none;
}
.input > .inputContainer > .shadow > .coloring > .cursor {
  margin-left: -8px;
  animation: blink 1s infinite ease-in-out;
}
@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.input > .inputContainer > .shadow > .modal {
  bottom: 22px;
  left: -6px;
  position: absolute;
  z-index: 100;
  background-color: #232323;
  padding: 0;
  box-shadow: inset 0 0 0 1px #505050;
  display: flex;
  flex-flow: column nowrap;
}
.modalUsage {
  padding: 4px 6px;
  margin-bottom: 1px;
  box-shadow: 0 1px 0 0 #505050;
}
.modalBody {
  padding: 6px 6px;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9e9e9e;
  position: relative;
  width: 100%;
}
.modalExample {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 8px;
}
.modalExample > h5 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  color: #bebebe;
}
.modalExample > figcaption {
  font-family: Roboto Mono;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9e9e9e;
}

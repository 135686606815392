.header {
  height: 144px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--gutter);
  padding-left: 32px;
  position: sticky;
  /*top: 72px;*/
  top: 0;
  z-index: 200;
  background-color: rgb(22, 22, 22);
}
.headerTitle,
.headerTools {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.headerTitle {
  gap: 16px;
}
.headerTools {
  justify-content: flex-end;
  gap: 20px;
}
.backButton {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #ededed;
  opacity: 0.5;
  transition: all 0.2s ease-in;
}
.backButton:hover {
  opacity: 1;
}
.backButton > svg {
  height: 30px;
  width: 30px;
  transform-origin: center;
  transform: rotate(180deg);
}
.title {
  font-weight: 900;
  font-size: 56px;
  line-height: 56px;
  color: #ededed;
}
/*.split {
  height: calc(100vh - 72px - 144px);
  max-height: calc(100vh - 72px - 144px);
  display: grid;
  grid-template-columns: calc(210px + 1px) 1fr;
  width: calc(100% - var(--gutter-double) + 10px);
  margin: 0 var(--gutter);
  margin-right: calc(var(--gutter) - 10px);
  column-gap: 22px;
  position: fixed;
  overflow: hidden;
  top: calc(72px + 144px);
}
.filter {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-right: 32px;
  border-right: 1px solid #282828;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 32px;
}*/
.main {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 10px;
  overflow-y: auto;
}
/*.filterCategoryList {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  position: relative;
}
.filterCategory {
  width: 100%;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  justify-content: flex-start;
  padding: 0 8px;
  height: 36px;
}*/
.filterCategory {
  justify-content: flex-start;
}
.filterCategoryList {
  gap: 12px;
}
.filterDivider {
  width: 100%;
  margin: 24px 0;
  min-height: 1px;
  background-color: #282828;
  display: block;
}
.filterGroup {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}
.filterGroupTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ededed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.filterGroupTitleLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
}
.filterGroupTitleLabel > svg {
  height: 18px;
  width: 18px;
}
.filterGroupButtons {
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
  width: 100%;
}
.filterGroupButton {
  background: #232323;
  border-radius: 6px;
  padding: 4px 6px;
  user-select: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #a0a0a0;
  transition: all 0.2s ease-in-out;
}
.filterGroupButton:not(.filterGroupButtonActive):hover {
  color: #ededed;
  background-color: #282828;
}
.filterGroupButton.filterGroupButtonActive {
  color: #161616;
  background-color: #ededed;
}
.main:has(.mainLoader) {
  height: calc(100vh - var(--nav-offset) - 144px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
}
.split > :global(.react-animated-router) {
  overflow: auto;
}
.searchBar {
  width: 220px;
  transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
}
.searchBarActive {
  width: 400px;
}
.creator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 42px;
  background-color: #282828;
  position: relative;
  border-radius: 6px;
  width: 100%;
  padding: 6px;
  gap: 5px;
}
.creatorButton {
  position: absolute;
  right: 6px;
  height: 30px;
  width: 30px;
}
.creatorButton > svg {
  height: 18px;
  width: 18px;
}
.creatorWrapper {
  display: flex;
  flex-flow: column nowrap;
  transform: translateX(-50px);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
}
.creatorWrapperActive {
  max-height: 150px;
  transform: translateX(0);
  opacity: 1;
}
.creatorLoader {
  position: absolute;
  left: -2px;
}
.creatorIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #A0A0A0;
}
.creatorName {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ededed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*.toolbar {
  height: 144px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--gutter);
}
.toolbarLabel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 32px;
}
.toolbarLabel > h1 {
  font-weight: 900;
  font-size: 56px;
  line-height: 56px;
}*/
.toolbarSwitch {
  width: 280px;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.toolbarSwitchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0px 0px 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #a0a0a0;
  background: var(--gray-dark-3, #232323);
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.toolbarSwitchButton:hover {
  background-color: #282828;
}
.toolbarSwitchButton:last-child {
  border-radius: 0px 6px 6px 0px;
}
.toolbarSwitchButton:disabled {
  background: var(--gray-dark-7, #3e3e3e);
  color: var(--gray-dark-12, #ededed);
  cursor: initial;
}
.toolbarSwitchButton:disabled:hover {
  cursor: initial;
} /*
.gauge {
  margin: 0 var(--gutter);
  margin-bottom: 32px;
  display: flex;
  flex-flow: column nowrap;
  background: #1c1c1c;
  border-radius: 10px;
  padding: 20px;
}
.gaugeTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;


  letter-spacing: 1.5px;
  text-transform: uppercase;



  color: #ededed;
}
.gaugeBar {
  margin-top: 24px;
  width: 100%;
  height: 10px;
  border-radius: 2px;
  background: #3e3e3e;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.gaugeBarFill {
  height: 10px;
  background-color: var(--yellow);
  display: block;
  border-radius: 2px;
}
.gaugeLabel {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}
.gaugeLabelBreakdown {
  gap: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.gaugeLabelGroup {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
}
.gaugeLabelGroupIcon {
  height: 10px;
  width: 10px;
  background-color: #3e3e3e;
  border-radius: 2px;
}
.gaugeLabelText {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;


  letter-spacing: 1.25px;
  text-transform: uppercase;



  color: #ededed;
}*/ /*
.items {
  margin: 0 var(--gutter);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: auto;
  gap: 32px;
  margin-bottom: 200px;
}
.item {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.itemRoot {
  width: calc(100% + 20px);
  top: -10px;
  left: -10px;
  height: calc(100% + 20px + 40px + 20px + 10px);

  display: block;
  position: absolute;

  z-index: 10;
  opacity: 0;
  transform: scale(0.9);

  transform-origin: center;
  user-select: none;
  pointer-events: none;
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.itemBackground {
  width: calc(100% + 20px);
  top: -10px;
  left: -10px;
  height: calc(100% + 20px + 40px + 20px + 10px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  border-radius: 6px;
  z-index: -1;
  background-color: #1c1c1c;
  opacity: 0;
  transform: scale(0.9);

  transform-origin: center;
  user-select: none;
  pointer-events: none;
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.itemThumbnail {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #1c1c1c;
  border-radius: 6px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.itemSize {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;


  text-align: right;
  letter-spacing: 1.25px;
  text-transform: uppercase;



  color: #a0a0a0;
}
.itemDetails {
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.itemName {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.itemStatus {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-top: 10px;
  color: #a0a0a0;
}
.itemAuthor {
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
}
.itemActions {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  left: 20px;
  user-select: none;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;
}
.itemAction {
  background: #ededed;
  border-radius: 6px;
  cursor: pointer;
  color: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemAction > svg {
  height: 20px;
  width: 20px;
}
.itemAction:last-child {
  background-color: #232323;
  color: #ededed;
}
.itemAction:hover {
  background-color: #fff;
}
.itemAction:last-child:hover {
  background-color: #282828;
}
.itemActive {

  z-index: 50;


  pointer-events: none;
}
.itemActive .itemRoot {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}
.itemActive .itemBackground {
  opacity: 1;
  transform: scale(1);
}
.itemDropdown {
  width: calc(100% - 40px);
  top: calc(100% - 10px);
  left: 20px;
  position: absolute;
  background: #282828;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  height: fit-content;
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 100;
  opacity: 0;
  transform-origin: top right;
  transform: scale(0.8);
  transition: all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
  pointer-events: none;
  user-select: none;
  padding: 6px;
  gap: 2px;
}
.itemActive .itemDropdownActive {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}
.itemDropdownAction {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  height: calc(20px + 16px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #ededed;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.itemDropdownAction > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 20px - 10px);
}
.itemDropdownActionDestructive {
  color: var(--tomato);
}
.itemDropdownAction:hover {
  background-color: #1c1c1c;
}
.itemDropdownAction > svg {
  height: 20px;
  width: 20px;
}
.itemDropdownSeparator {
  height: 1px;
  width: calc(100% - 20px);
  background-color: #3e3e3e;
  margin: 4px;
}*/
.rootLoader {
  width: 100%;
  height: calc(100vh - 72px - 144px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid {
  width: 100%;
  padding-top: 10px;
  padding-left: 32px;
  padding-right: var(--gutter);
}
.formError {
  color: #e54d2ef2;
  margin-left: 6px;
  gap: 4px;
}
.formError > svg {
  height: 16px;
  width: 16px;
}

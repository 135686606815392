.error {
  color: #ff0000;
}
.table {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
  --columns: 1fr;
  row-gap: 10px;
}
.tableRow {
  width: 100%;
  display: grid;
  grid-template-columns: var(--columns);
  padding: 0 10px;
  column-gap: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #ededed;
}
.tableHeader {
  background: #1c1c1c;
  border-radius: 6px;
  height: 40px;
  /* margin-bottom: 10px; */
}
.tableRow:not(.tableHeader) {
  height: 50px;
  box-shadow: inset 0 -1px 0 0 #232323;
  padding-bottom: 10px;
}
.tableCell {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.tableCell:last-child {
  justify-content: flex-end;
}
.tableHeader > .tableCell {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
}
.tableHeader > .tableCell:hover {
  color: #fff;
}

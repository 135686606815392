.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #161616;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loaderHide {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
body .toast:not(:has(:global(.task-toast))) {
  border-radius: 106px;
  /* padding: 0 20px;
  height: 48px;*/

  padding: 5px 20px;
  min-height: 48px;
  height: auto;
  gap: 8px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
}
body .toast > div[role='status'] {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
body .toastSuccess {
  background: rgba(153, 213, 42, 0.85);
  color: var(--gray-dark-1, #161616);
}
body .toastSuccess > div > div > div {
  background-color: #161616;
}
body .toastSuccess > div > div > div::after {
  border-color: rgba(153, 213, 42, 0.95);
}
body .toastError {
  background-color: #e54d2ef2;
  color: #feefec;
  gap: 8px;
}
body .toastError > div:has(div > div) {
  /*transform-origin: center;
  transform: scale(0.9);*/
}
body .toastError > div > div:has(div) {
  /*background-color: #161616;*/
  border-radius: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  opacity: 1;
  transform: none;
  background-image: url(./toastError.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e54d2ef2;
}
body .toastError > div > div > div {
  display: none;
}
body .toastError > div > div > div::before,
body .toastError > div > div > div::after {
  /*background-color: rgba(255, 47, 47, 0.96);*/
}
body .toastLoading > div > div {
  /*height: 16px;
  width: 16px;*/
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 0;
  background-image: url(./toastLoading.svg);
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center;
}
body .toastLoading {
  background-color: #edededf2;
  background-size: contain;
  color: #161616;
}

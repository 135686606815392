.root {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
}
.root:has(.rootLoader) {
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--nav-offset));
}
.header {
  position: sticky;
  top: var(--nav-offset);
  height: 144px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #161616;
  z-index: 200;
  padding: 0 var(--gutter);
}
.headerTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 56px;
  font-weight: 900;
  line-height: 56px;
}
.wrapper {
  margin-top: 10px;
  padding: 0 var(--gutter);
  margin-bottom: 32px;
}

.assetPreview {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #2e2e2e;
}
.assetIcon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  user-select: none;
  background-color: #1c1c1c;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.defaultIcon {
  height: 22px;
  width: 22px;
  color: #a0a0a0;
}
.assetIcon > picture {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.assetDetails {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  overflow: hidden;
}
.assetTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #ededed;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3px;
}
.assetVisibility {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  user-select: none;
  color: #87be22;
}
.assetVisibilityPrivate {
  color: #a0a0a0;
}
.floatButton {
  position: absolute;
  bottom: 32px;
  width: calc(100% - 32px - var(--gutter));
}

.toggle {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  position: relative;
  width: 32px;
  height: 20px;
  box-shadow: 0 0 0 2px inset #a0a0a0;
  border-radius: 50px;
  cursor: pointer;
  --offset: 4px;
  --size: 12px;
}
.toggle:not(.toggleActive):not(:disabled):hover {
  box-shadow: 0 0 0 2px inset #ededed;
}
.toggle:disabled {
  box-shadow: 0 0 0 2px inset #343434;
  cursor: not-allowed;
}
.toggle.toggleActive {
  box-shadow: none;
  background: #68ddfd;
}
.toggle.toggleActive:not(:disabled):hover {
  background: #8ae8ff;
}
.toggle.toggleActive:disabled {
  background: #083e59;
}
.toggleHandle {
  height: var(--size);
  width: var(--size);
  left: var(--offset);
  position: absolute;
  background: #a0a0a0;
  border-radius: 100%;
  transition: all 0.25s ease-out;
}
.toggle:not(:disabled):not(.toggleActive):hover .toggleHandle {
  background: #ededed;
}
.toggle:disabled:not(.toggleActive) .toggleHandle {
  background: #343434;
}
.toggleActive .toggleHandle {
  left: calc(100% - var(--offset) - var(--size));
  background: #161616;
}

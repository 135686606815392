.header::before {
  background-image: url(../../assets/images/usersIcon.png);
}
.header {
  height: auto;
  display: flex;
  flex-flow: column nowrap;
}
.headerInfo {
  gap: 24px;
}
.headerFigure {
  height: 88px;
  width: 140px;
  position: relative;
}
.headerFigure > picture,
.headerFigure > figure {
  flex-shrink: 0;
  height: 88px;
  width: 88px;
  border-radius: 50%;
}
.headerFigure > picture {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.headerFigure > picture::before {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 24px 4px var(--theme, rgba(0, 0, 0, 0)),
    0 0 10px 4px var(--theme, rgba(0, 0, 0, 0));
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  opacity: 0.55;
  mix-blend-mode: lighten;
}
.headerFigure > figure {
  box-shadow: 0 0 0 4.5px #1c1c1c;
  background: var(--purple-dark-4, #3a1e48);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  color: #bf7af0;
}
.headerFigure > figure > svg {
  height: 50px;
  width: 50px;
}
.headerText {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}
.headerTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px; /* 100% */
}
.headerSubtitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.headerActions {
  margin-top: 28px;
  gap: 8px;
}
.headerInput {
  width: 400px;
}
.headerDescription {
  margin-top: 8px;
  color: var(--gray-dark-10, #7e7e7e);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.head {
  width: 100%;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 32px;
}
.head > div {
  gap: 12px;
}
.headTitle {
  gap: 10px;
}
.tableInvite > span {
  color: #a0a0a0;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableStatus > span {
  padding: 6px 10px;
  border-radius: 50px;
  background: var(--green-dark-4, #113123);
  color: var(--green-dark-11, #4cc38a);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.tableStatusUsed > span {
  color: var(--sky-dark-11, #2ec8ee);
  background: var(--sky-dark-4, #082d41);
}

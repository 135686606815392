.splitRoot {
  display: grid;
  grid-template-columns: calc(var(--gutter) + 224px + 32px + 1px) 1fr;
  gap: 0;
  width: 100%;
  height: calc(100vh - 72px);
  position: relative;
}
.splitLoading,
.splitError {
  display: flex;
  align-items: center;
  height: calc(100vh - 72px);
  justify-content: center;
}
.splitErrorMessage {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.splitAside {
  padding: 32px 32px;
  padding-left: var(--gutter);
  height: 100%;
  border-right: 1px solid #282828;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  overflow-y: auto;
}
.splitContent {
  padding: 40px 32px;
  padding-top: 0;
  padding-right: var(--gutter);
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
}
.splitContent > :global(.animated-router-container) {
  width: 100%;
  min-height: 100%;
}
.splitContent > div > :global(.animated-router-container) {
  width: 100%;
  height: 100%;
}
.splitAsideNav {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
  gap: 12px;
}
.splitAside .splitAsideNavLink {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 38px;
  padding: 0 8px;
  font-size: 14px;
  user-select: none;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #a0a0a0;
  background-color: transparent;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}
.splitAside
  .splitAsideNavLink:not(.splitAsideNavLinkActive):not(
    .splitAsideNavLinkDisabled
  ):hover {
  background-color: #282828;
  color: #ededed;
}
.splitAside .splitAsideNavLink.splitAsideNavLinkActive {
  background-color: #ededed;
  color: #161616;
  cursor: default;
}
.splitAside .splitAsideNavLinkActive:hover {
  background-color: #ffffff;
}
.toggleGroup {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
}
.toggleGroupLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
  color: #ededed;
}
.splitContentWrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 40px 32px;
  padding-right: var(--gutter);
  padding-bottom: 32px;
}
.splitContentWrapperSlim {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.splitContentWrapperLimit {
  max-width: 640px;
  width: 100%;
}
.splitContentWrapperLimit .splitContentSection:last-child {
  padding-bottom: 0;
}
.splitContentWrapper:has(.splitContentLoader),
.splitContentWrapperSlim:has(.splitContentLoader) {
  height: 100%;
}
.splitContentLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.splitContentTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}
.splitContentSubtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.formGroup {
  gap: 8px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
  max-width: 640px;
}
.splitContentSection {
  gap: 28px;
  padding: 32px 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.splitContentDivider {
  height: 1px;
  width: 100%;
  display: block;
  background-color: #282828;
}
.formGroupLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #a0a0a0;
}
@keyframes formGroupErrorEnter {
  from {
    transform: translateY(-4px);
    opacity: 0;
  }
}
.formGroupError {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ca5639;
  margin-top: -2px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
  animation: formGroupErrorEnter 0.3s ease-in;
}
.formGroupError::before {
  content: '';
  height: 15px;
  width: 15px;
  background-image: url(../assets/icons/warningIconRed.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.formGroupInput {
  width: 100%;
}
.toggleGroup {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
}
.toggleGroupLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
  color: #ededed;
}
.rowFull {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.splitContentTag {
  border-radius: 6px;
  display: inline-flex;
  background-color: #232323;
  padding: 4px 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transform: translateY(4px);
  color: #a0a0a0;
}
.label {
  padding: 6px 10px;
  display: inline-flex;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.labelSky {
  color: #2ec8ee;
  background-color: #082d41;
}
.labelGrass {
  color: #63c174;
  background-color: #16301d;
}
.splitAsideNavLinkDisabled {
  gap: 10px;
  opacity: 0.5;
  cursor: not-allowed;
}
.splitAsideNavLinkDisabled > span {
  padding: 1px 6px;
  color: #ededed;
  font-size: 7px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  border-radius: 20px;
  background: #3e3e3e;
}
.splitContentHeader {
  height: 216px;
  display: flex;
  /*padding: 0 var(--gutter);*/
  width: 100%;
  flex-flow: column nowrap;
  position: sticky;
  padding: 40px var(--gutter);
  padding-left: 32px;
  top: 0;
  z-index: 200;
  background-color: #161616;
  gap: 32px;
}
.splitContentHeaderTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 56px;
  font-weight: 900;
  line-height: 56px;
}
.splitContentHeaderTools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: 100%;
}
.splitContentHeaderToolsRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
}

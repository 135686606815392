.domainName {
  padding-left: 10px;
}
.domainName > input {
  padding-left: 0;
}
.domainName .prefix,
.domainName .suffix {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 14px;
  user-select: none;
  pointer-events: none;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.domainName .overlay {
  position: absolute;
  height: 100%;
  left: calc(10px + 45.6px);
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  width: fit-content;
  max-width: 100%;
  align-items: center;
  pointer-events: none;
  user-select: none;
}
.domainName .shadow {
  opacity: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.error {
  color: #e54d2ef2;
  margin-left: 6px;
  gap: 4px;
}
.error > svg {
  height: 16px;
  width: 16px;
}
.worldLink {
  color: #d34c7f;
}
.worldLink:hover {
  text-decoration: underline;
}

.dropDown {
  max-width: 340px;
  min-width: 200px;
  width: fit-content;
  position: absolute;
  top: -10px;
  background-color: #282828;
  border-radius: 6px;
  transform-origin: top left;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  display: grid;
  gap: 0;
  transform: scale(0.85);
  transition: transform 0.2s cubic-bezier(0.36, 0, 0.66, -0.56),
    opacity 0.2s cubic-bezier(0.36, 0, 0.66, -0.56);
  grid-template-rows: repeat(auto-fill, 36px);
  padding: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.dropDownActive {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1),
    opacity 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.dropDownButton {
  width: 100%;
  padding: 8px;
  cursor: pointer;
  color: #a0a0a0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  user-select: none;
}
.dropDownButton:not(.dropDownButtonActive):hover {
  background-color: #1c1c1c;
  color: #ededed;
}
.dropDownButton > svg {
  height: 20px;
  width: 20px;
}
.dropDownButtonActive {
  color: #161616;
  cursor: default;
  background: var(--gray-dark-12, #ededed);
}
.logo {
  width: calc(224px + 32px + 1px);
  flex-shrink: 0;
  border-right: 1px solid #232323;
  padding-right: 32px;
  height: 100%;
  flex-flow: row nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.navList {
  flex-grow: 1;
  width: 100%;
  display: flex;
  gap: 20px;
  height: 100%;
  flex-flow: row nowrap;
  align-items: flex-start;
  position: relative;

  /* margin-left: 32px;
  margin-right: 32px;*/
  padding: 0 32px;
  overflow-x: auto;
}
.navList::-webkit-scrollbar,
.navList::-webkit-scrollbar-track,
.navList::-webkit-scrollbar-thumb,
.navList::-webkit-scrollbar-thumb:hover {
  display: none;
  opacity: 0;
  max-height: 0;
  max-width: 0;
  width: 0;
  height: 0;
  min-height: 0;
  min-width: 0;
  pointer-events: none;
  user-select: none;
  visibility: hidden;
  background: transparent;
  border: none;
  box-shadow: none;
}
.navListStatic {
  overflow: hidden;
}
.navOption {
  cursor: pointer;
  height: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  letter-spacing: 1.5px;
  text-transform: uppercase;
  position: relative;
  padding: 0 10px;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 0 #fff;
  position: relative;

  /*padding-bottom: 2px;*/
  gap: 4px;

  transition: all 0.2s ease-in-out;
}

.menuWrapper {
  height: calc(100vh - 72px);
  top: 72px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 700;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
}
.menuWrapperActive {
  pointer-events: auto;
}
.menu {
  top: 0;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  opacity: 0;
  background-color: red;
  height: 300px;
  width: 126px;
  transition: opacity 0.2s ease-in-out;
  transform: translateX(-50%);
}
.menuWrapperActive .menu {
  opacity: 1;
}
.navOption > svg {
  height: 20px;
  width: 20px;
  user-select: none;
  transform-origin: center;
}
.navOption.active {
  color: #ededed;
  box-shadow: inset 0 -2px 0 0 #fff;
}
.navOption.active > svg {
  transform: rotate(180deg);
}
.navDropdown.active {
  box-shadow: none;
}
.navOptionList {
  position: absolute;
  top: 71px;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.options {
  gap: 20px;
  padding-left: 32px;
  height: 100%;
  flex-flow: row nowrap;
  display: flex;
  align-items: center;
  border-left: 1px solid #232323;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid var(--theme, #a0a0a0);
  border-radius: 99px;
  cursor: pointer;

  transition: all 0.2s ease-in-out;
}
.avatar > img {
  width: 30px;
  height: 30px;
  border-radius: 99px;
}
.avatar:hover {
  border-color: #ededed;
}

.mode {
  user-select: none;
  color: #ededed;

  position: relative;

  border-right: 1px solid #232323;
  padding: 0 32px;

  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.mode.active {
  background: #232323;
}
.modeItem {
  svg {
    width: 24px;
    height: 20px;
    margin-right: 12px;
  }

  display: flex;
  align-items: center;
}

.mode-dropdown {
  position: absolute;

  top: calc(100% + 0px);
  left: -1px;

  width: 310px;
  list-style: none;

  /*filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));*/

  background: #232323;

  display: none;
}
.mode-dropdown.show {
  display: block;
}

.mode-option {
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 15px 34px;
  padding: 0 34px;
  gap: 14px;
  color: #ededed;

  height: 56px;

  border-bottom: 1px solid #2e2e2e;
  transition: all 0.2s ease-in-out;

  & > span > svg {
    height: 14px;
    width: 14px;
  }
  & > span {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a0a0a0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: #2ec8ee;
    background-color: #282828;
  }
  &:hover > span {
    color: #2ec8ee;
  }
}

.mode-option:last-of-type {
  border-bottom: none;
}

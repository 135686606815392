.versionWrapper {
  height: 94px;
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--gray-dark-5, #2e2e2e);
  background: var(--gray-dark-3, #232323);
}
.versionRoot {
  padding: 10px 14px 10px 10px;
}
.versionInfo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
}
.versionInfoIcon {
  border-radius: 6px;
  background: #1c1c1c;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  user-select: none;
  color: #a0a0a0;
}
.versionInfoIcon > svg {
  height: 20px;
  width: 20px;
}
.versionStripe {
  width: 100%;
  height: 6px;
  border-radius: 0px 0px 6px 6px;
  background-color: var(--primary);
  user-select: none;
  flex-shrink: 0;
  display: block;
}
.versionInfoText {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.versionInfoTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.versionInfoSubtitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
}
.versionText {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}
.versionStatus {
  color: var(--secondary);
  font-size: 14px;
  font-weight: 600;
  line-height: 15px; /* 114.286% */
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: var(--primary);
  border-radius: 6px;
}
.versionIcon {
  height: 20px;
  width: 20px;
  color: var(--primary);
}
.filePickerModal > figure {
  align-items: center;
  justify-content: center;
  height: 320px;
  background-color: #121319;
}
.filePickerModal > figure > button {
  top: -14px;
  right: -14px;
}
/*.filePickerLoader {
    position: absolute;
    animation: loaderHold 0.5s linear, loaderIn 0.2s ease-in 0.5s;
  }
  @keyframes loaderHold {
    from,
    to {
      opacity: 0;
    }
  }
  @keyframes loaderIn {
    from {
      opacity: 0;
    }
  }*/
.dropZone {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #121623;
  border-radius: 4px;
  border: 2px dashed #11308f;
  transform-origin: center center;
  transition: all 0.3s cubic-bezier(0.36, 0, 0.66, -0.56),
    box-shadow 0.3s ease-in-out;
  user-select: none;
}
.dropZoneActive {
  /* should pop out massively */
  box-shadow: 0 0 0 8px #11308f;
  background-color: #222942;
  border: 2px dashed #0038df00;
}
.beacon {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  user-select: none;
  z-index: 10000;
  background-color: rgba(50, 50, 255, 0.1);
  opacity: 0;
  transition: opacity 0.07s ease-in-out;
}
.beaconActive {
  opacity: 1;
}

/*.dropZoneHide {
    opacity: 0;
    pointer-events: none;
    transform: scale(0.5);
  }*/
.dropZoneTitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}
.dropZoneActive .dropZoneTitle {
  color: #fff;
}
.dropZoneDivider {
  width: 300px;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 5px;
  display: block;
  background-color: #fff;
  opacity: 0.25;
}

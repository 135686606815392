.root {
  background: #0f0f0f;
  display: flex;
  width: 100%;
  padding: 0 var(--gutter);
  flex-flow: column nowrap;
  padding-top: 48px;
  margin-top: 86px;
}
.rootOverflow {
  width: calc(100% + var(--gutter-double));
  margin-left: calc(-1 * var(--gutter));
}
.rootSlim {
  padding-left: 32px;
}
.nav {
  display: grid;
  grid-template-columns: 4fr 5fr 4fr 4fr 4fr;
  width: 100%;
  padding-bottom: 86px;
  box-shadow: 0 1px 0 0 #232323;
  margin-bottom: 1px;
}
.navColumn {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.navSocial {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 24px;
}
.navSocialLink {
  width: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a0a0a0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform-origin: center bottom;
}
.navSocialLink:hover {
  color: var(--gray-dark-12, #ededed);
  transform: translateY(-1px) scale(1.1);
  filter: drop-shadow(0 2px 10px rgba(255, 255, 255, 0.05))
}
.navSocialLink > svg {
  height: 100%;
}
.navLogo {
  width: 100%;
  aspect-ratio: 126/15;
  max-width: 126px;
  height: auto;
  user-select: none;
}
.navColumnTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.navList {
  margin-top: 20px;
  gap: 20px;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  display: flex;
  flex-flow: column nowrap;
}
.navList > a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transform-origin: center bottom;
}
.navList > a > svg {
  height: 18px;
  width: 18px;
}
.navList > a:hover {
  color: var(--gray-dark-12, #ededed);
  text-shadow: 0 2px 10px rgba(255, 255, 255, 0.05);
}
.newsletter {
  margin: 48px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.newsletterText {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  flex-shrink: 0;
}
.newsletterText > h2 {
  color: var(--gray-dark-12, #ededed);
  font-size: 24px;
  font-weight: 500;
  line-height: 24px; /* 100% */
}
.newsletterText > p {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 23px; /* 125% */
  max-width: 350px;
}
.newsletterForm {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 1;
}
.newsletterInput {
  width: 50%;
  max-width: 400px;
}
.newsletterThanks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  width: 50%;
  max-width: 300px;
  gap: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px; /* 125% */
  color: var(--gray-dark-11, #a0a0a0);
}
.newsletterThanks > svg {
  height: 20px;
  width: 20px;
  /* green */
  color: #14db14;
}
@media screen and (max-width: 1170px) {
  .rootSlim .newsletter {
    flex-flow: column nowrap;
    justify-content: flex-start;
  }
  .rootSlim .newsletterText {
    margin-bottom: 24px;
    width: 100%;
    gap: 12px;
  }
  .rootSlim .newsletterThanks {
    width: 100%;
    max-width: none;
    background-color: #161616;
    padding: 8px 0;
    border-radius: 4px;
  }
  .rootSlim .newsletterText > p {
    width: 100%;
    max-width: none;
  }
  .rootSlim .newsletterForm {
    width: 100%;
    max-width: none;
    justify-content: flex-start;
  }
  .rootSlim .newsletterInput {
    flex-grow: 1;
    max-width: none;
  }
}

@media screen and (max-width: 850px) {
  .newsletter {
    flex-flow: column nowrap;
    justify-content: flex-start;
  }
  .newsletterText {
    margin-bottom: 24px;
    width: 100%;
    gap: 12px;
  }
  .newsletterThanks {
    width: 100%;
    max-width: none;
    background-color: #161616;
    padding: 8px 0;
    border-radius: 4px;
  }
  .newsletterText > p {
    width: 100%;
    max-width: none;
  }
  .newsletterForm {
    width: 100%;
    max-width: none;
    justify-content: flex-start;
  }
  .newsletterInput {
    flex-grow: 1;
    max-width: none;
  }
}
@media screen and (max-width: 450px) {
  .newsletterForm {
    flex-flow: column nowrap;
    gap: 8px;
  }
  .newsletterInput,
  .newsletterForm > button {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .rootSlim .nav {
    grid-template-columns: 4fr 1fr 4fr 4fr 4fr;
  }
}
@media screen and (max-width: 1100px) {
  .nav {
    grid-template-columns: 4fr 1fr 4fr 4fr 4fr;
  }
}
@media screen and (max-width: 1230px) {
  .rootSlim .nav {
    grid-template-columns: minmax(300px, 1fr) 1fr 1fr;
    row-gap: 64px;
  }
  .rootSlim .navSpacer {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .nav {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 64px;
  }
  .navSpacer {
    display: none;
  }
}
@media screen and (max-width: 1050px) {
  .rootSlim .nav {
    grid-template-columns: minmax(300px, 1fr) 1fr;
  }
}
@media screen and (max-width: 850px) {
  .nav {
    grid-template-columns: minmax(300px, 1fr) 1fr;
  }
}
@media screen and (max-width: 550px) {
  .nav {
    grid-template-columns: 1fr;
    gap: 48px;
  }
  .navColumn:first-child {
    padding-bottom: 84px;
  }
}
.footer {
  padding: 48px 0;
  box-shadow: 0 -1px 0 0 #232323;
  margin-top: 1px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  width: 100%;
}
.footerLeft,
.footerRight {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.footerRight {
  justify-content: flex-end;
}
.status {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 36px;
  padding: 0 12px;
  justify-content: center;
  gap: 12px;
  color: var(--green-dark-9, #30a46c);
  font-size: 16px;
  border-radius: 6px;
  background: var(--green-dark-4, #113123);
  font-weight: 500;
  line-height: 20px; /* 125% */
  user-select: none;
}
.status > span {
  display: block;
  flex-shrink: 0;
  border-radius: 71px;
  background: var(--green-dark-9, #30a46c);
  height: 8px;
  width: 8px;
}
@media screen and (max-width: 900px) {
  .rootSlim .footer {
    flex-flow: column nowrap;
    gap: 12px;
  }
  .rootSlim .footerLeft,
  .rootSlim .footerRight {
    width: 100%;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 700px) {
  .footer {
    flex-flow: column nowrap;
    gap: 12px;
  }
  .footerLeft,
  .footerRight {
    width: 100%;
    justify-content: flex-start;
  }
}

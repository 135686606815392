.breadcrumbs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 var(--gutter);
  width: calc(100% - var(--gutter-double));
  padding-top: 40px;
  gap: 10px;
}
.breadcrumb {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #a0a0a0;
}
.breadcrumb:hover {
  color: #ededed;
}
.breadcrumbDivider {
  height: 4px;
  width: 4px;
  background-color: #707070;
  user-select: none;
  border-radius: 100%;
}
.header {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 var(--gutter);
  margin-top: 20px;
  position: relative;
  width: calc(100% - var(--gutter-double));
}
.title {
  font-weight: 900;
  font-size: 56px;
  line-height: 56px;
  color: #ededed;
}
.headerDetails {
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
}
.headerAuthor {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.headerAuthorAvatar {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.headerAuthorName {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #a0a0a0;
}
.headerAuthor:hover .headerAuthorName {
  color: #ededed;
}
.headerDivider {
  height: 28px;
  width: 1px;
  background-color: #232323;
  user-select: none;
}
.headerRating {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.headerRatingTag {
  padding: 4px 6px;
  background: #232323;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ededed;
}
.headerRatingText {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #a0a0a0;
}
.split {
  display: grid;
  column-gap: 40px;
  margin: 0 var(--gutter);
  margin-top: 40px;
  grid-template-columns: 1fr clamp(300px, 35%, 440px);
}
.info,
.aside {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.aside {
  align-items: center;
  gap: 10px;
}
.jumbotron {
  width: 100%;
  aspect-ratio: 710/400;
  height: auto;
  background-color: #161616;
  border: 1px solid #232323;
  border-radius: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.jumbotronIcons {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  right: 12px;
  gap: 6px;
}
.jumbotronIcon {
  height: 10px;
  width: 16px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}
.jumbotronIcon:disabled {
  background-color: rgba(255, 255, 255, 0.8);
  cursor: not-allowed;
  height: 46px;
}
.details {
  background: #1c1c1c;
  border-radius: 6px;
  padding: 40px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  width: 100%;
}
.detailsPrice {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
}
.detailsPriceIcon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  color: #ededed;
}
.detailsPriceText {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #ededed;
}
.detailsPriceSubtext {
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-decoration-line: line-through;
  text-transform: uppercase;
  color: #a0a0a0;
}
.detailsPriceTag {
  margin-left: 20px;
  padding: 4px 8px;
  background: #e93d82;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ffffff;
}
.detailsActions {
  margin-top: 40px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.detailsAction {
  width: 100%;
}
.detailsStats {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  margin-top: 32px;
}
.detailsStat {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
  position: relative;
  margin-bottom: 17px;
}
.detailsStat::after {
  bottom: -1px;
  height: 1px;
  width: 100%;
  position: absolute;
  pointer-events: none;
  background-color: #282828;
  content: '';
}
.detailsStat:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.detailsStat:last-child::after {
  display: none;
}
.detailsStatTitle {
  color: #a0a0a0;
}
.detailsStatText {
  color: #ededed;
  gap: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.detailsStatText > svg {
  height: 16px;
  width: 16px;
  user-select: none;
}
.infoTabs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
  align-items: center;
  height: 44px;
  position: relative;
  gap: 20px;
}
.infoTab {
  height: 100%;
  position: relative;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoTabActive {
  color: #ededed;
}
.infoTab::after {
  content: '';
  bottom: 0;
  width: 100%;
  background: #ededed;
  height: 2px;
  position: absolute;
  left: 0;
  opacity: 0;
}
.infoTabActive::after {
  opacity: 1;
}
.infoContent {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  /* padding-bottom: 64px;*/
  padding-bottom: 32px;
}
.infoContentDescription {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
  word-break: break-word;
  word-wrap: break-word;
  color: #ededed;
}
.tags {
  margin-top: 32px;
  gap: 10px;
  display: flex;
  flex-flow: column nowrap;
}
.tagsTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.tagsWrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
}
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: var(--gray-dark-3, #232323);
  user-select: none;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  padding: 4px 6px;
}

.notice {
  height: 52px;
  background: #2b1400;
  border-radius: 6px;
  display: flex;
  padding-left: 20px;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ededed;
}
.releases {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  width: 100%;
}
.release {
  height: 72px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  background: #232323;
  border: 1px solid #2e2e2e;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: all 0.2s ease-in-out;
}
.release:hover {
  background-color: #282828;
}
.releaseInfo {
  gap: 4px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.releaseVersion {
  color: #ededed;
}
.releaseDate {
  color: #a0a0a0;
}
.releaseDownload {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ededed;
  color: #161616;
}
.releaseDownload:not(:disabled):hover {
  background-color: #ffffff;
}
.releaseDownload > svg {
  height: 20px;
  width: 20px;
}
.releaseDownload:disabled {
  background-color: #232323;
  color: #707070;
  cursor: not-allowed;
}
.divider {
  width: calc(100% - var(--gutter-double));
  height: 1px;
  background-color: #232323;
  user-select: none;
  margin: 0 var(--gutter);
}
.discover {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - var(--gutter-double));
  margin: 0 var(--gutter);
  margin-top: 30px;
  margin-bottom: 128px;
}
.discoverHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
  color: #ededed;
}
.discoverTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}
.discoverAuthor {
  gap: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}
.discoverAuthorAvatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.discoverAuthorName {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.vaultLink {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: #1e260d;
  border-radius: 6px;
  height: 52px;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ededed;
  user-select: none;
  width: 100%;
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}
.vaultLink > svg {
  color: #99d52a;
  height: 20px;
  width: 20px;
}
.vaultLink:active {
  transform: scale(0.97);
}
.root:has(.loader),
.root:has(.error) {
  height: calc(100vh - var(--nav-offset));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.root {
  padding-bottom: 32px;
  padding-bottom: 0;
}
.error {
  color: red;
}
.infoContentDescription a,
.infoContentDescription .a {
  color: #d64c81;
  cursor: pointer;
}
.infoContentDescription a:hover,
.infoContentDescription .a:hover {
  color: #e93d82;
  text-decoration: underline;
}
.infoContentDescription li {
  padding-inline-end: 14px;
  transform: translateX(14px);
}
.infoContentDescription h1 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 2px;
}
.infoContentDescription h2 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.infoContentDescription h3 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 2px;
}
.infoContentDescription h4 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 2px;
}
.infoContentDescription h5 {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  margin-bottom: 2px;
}
.infoContentDescription h6 {
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  margin-bottom: 2px;
}
.infoContentDescription hr {
  width: 100%;
  height: 1.5px;
  background-color: #ededed;
  border-radius: 2px;
}
.jumbotronFrame::after {
  content: '';
  display: table;
  clear: both;
}
.jumbotronScroller {
  width: 100%;
  height: 74px;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow-x: scroll;
  overflow-x: overlay;
  height: 74px;
  gap: 10px;
  margin-top: 20px;
  scrollbar-width: thin;
}
.jumbotronThumb {
  height: 74px;
  width: calc(20% - 8px);
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 6px;
  flex-shrink: 0;
  cursor: pointer;
  background-color: var(--gray-dark-3, #232323);
  opacity: 0.7;
  transition: all 0.2s ease-in;
}
.jumbotronThumb:disabled {
  border: 2px solid var(--gray-dark-12, #ededed);
  opacity: 1;
}
@media screen and (max-width: 1100px) {
  .jumbotronThumb {
    width: calc(25% - 7.5px);
  }
}
@media screen and (max-width: 950px) {
  .jumbotronThumb {
    width: calc(33.333% - 5px);
  }
}
.priceConfirmation {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  color: var(--gray-dark-11, #a0a0a0);
}
.priceConfirmation > span {
  gap: 6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.priceConfirmation > span > svg {
  height: 16px;
  width: 16px;
}
.priceConfirmation > h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.priceConfirmationHead {
  border-radius: 6px;
  background: var(--yellow-dark-3, #2c2100);
  height: 48px;
  color: var(--yellow-dark-11, #f0c000);
}
.priceConfirmationHead > h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.priceConfirmationHead > span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.priceConfirmationHead > span > svg {
  height: 20px;
  width: 20px;
}
.priceWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 12px;
}
.jumbotron > :global(.yt-lite) {
  width: 100%;
  border-radius: 6px;
}
.jumbotron > :global(.yt-lite) > button {
  cursor: pointer;
}
.jumbotron > :global(.yt-lite) > iframe {
  border-radius: 6px;
}
.assets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  width: 100%;
}
.assetThumbnail {
  border-radius: 6px;
  background-color: #1c1c1c;
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 6px;
}
.asset > picture {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.root {
  width: 100%;
  padding: 40px var(--gutter);
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding-bottom: 32px;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  height: calc(100vh - 72px);
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.title {
  color: var(--gray-dark-12, #ededed);
  font-size: 56px;
  font-weight: 900;
  line-height: 56px; /* 100% */
}
.grid {
  margin-top: 40px;
  padding: 10px 0;
  row-gap: 20px;
  column-gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  display: grid;
}
.package {
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  cursor: pointer;
}
.packageImage {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 6px;
  background: #1c1c1c;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
}
.packageImage > picture {
  aspect-ratio: 1/1;
  width: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.packageAmount {
  margin-top: 5%;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px; /* 100% */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.packageDiscount {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: #e5484d;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  color: var(--White, #fff);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.packageInfo {
  padding: 10px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-flow: column nowrap;
  flex-shrink: 0;
}
.packageName {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px; /* 100% */
}
.packagePrice {
  color: var(--gray-dark-12, #ededed);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-top: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.packageNormalPrice {
  color: var(--gray-dark-11, #a0a0a0);
  text-decoration: line-through;
}
.packageWide {
  grid-column: 2/4;
}
.packageWide .packageImage {
  height: auto;
  width: 100%;
  aspect-ratio: 700/330;

  box-shadow: inset 0 0 0 1px var(--yellow-dark-11, #f0c000);
}
.packageWide .packageImage > picture {
  aspect-ratio: 0;
  width: 100%;
  height: 50%;
}
.packageWide .packageAmount {
  background-clip: none;
  -webkit-background-clip: none;
  color: var(--yellow-dark-11, #f0c000);
  -webkit-text-fill-color: var(--yellow-dark-11, #f0c000);
}
.packageActive {
  z-index: 100;
}
.packageOverlay,
.packageBackground {
  top: -10px;
  left: -10px;
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 20px + 20px + 20px + 16px);
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-out, transform 0.2s ease-in;
  display: flex;
  border-radius: 6px;
}
.packageOverlay {
  z-index: 1000;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  transform-origin: top center;
  transform: scale(0.9);
  cursor: pointer;
}
.packageBackground {
  background: var(--gray-dark-2, #1c1c1c);
  box-shadow: 0px 7.85456px 19.6364px 0px rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.packageActive .packageOverlay,
.packageActive .packageBackground {
  transition: all 0.2s ease-in, transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  opacity: 1;
}
.packageActive .packageOverlay {
  pointer-events: auto;
  transform: scale(1);
}
.packageOverlayButton {
  width: 100%;
}
.divider {
  width: 100%;
  height: 1px;
  display: block;
  background: #232323;
  margin-top: 80px;
  margin-bottom: 32px;
}
.disclaimer {
  width: 100%;
  color: var(--gray-dark-9, #707070);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.disclaimer > a {
  text-decoration: underline;
  /* move the line down a bit */
  text-underline-offset: 1px;
  transition: all 0.2s ease-in;
}
.disclaimer > a:hover {
  color: var(--yellow-dark-11, #d2d2d2);
}
@media screen and (max-width: 1100px) {
  .packageName {
    font-size: 17px;
    line-height: 17px;
  }
  .packagePrice {
    font-size: 14px;
    line-height: 14px;
  }
}
@media screen and (max-width: 1000px) {
  .packageAmount {
    font-size: 28px;
    line-height: 28px;
  }
}
@media screen and (max-width: 950px) {
  .packageDiscount {
    font-size: 12px;
    line-height: 12px;
    border-radius: 4px;
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    column-gap: 20px;
    row-gap: 10px;
  }
}
@media screen and (max-width: 700px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .packageWide {
    grid-column: 1/3;
  }
  .packageWide .packageImage {
    height: auto;
    width: 100%;
    aspect-ratio: 630/330;
    box-shadow: inset 0 0 0 1px var(--yellow-dark-11, #f0c000);
  }
}
@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .packageWide {
    grid-column: 1/2;
  }
  .packageWide .packageImage {
    height: auto;
    width: 100%;
    aspect-ratio: 630/330;
    box-shadow: inset 0 0 0 1px var(--yellow-dark-11, #f0c000);
  }
}

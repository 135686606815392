.item {
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
}
.itemImage {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  display: flex;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #1c1c1c;
  border-radius: 6px;
  padding-bottom: 10px;
  padding-left: 10px;
  flex-flow: column nowrap;
  justify-content: flex-end;
  transition: background-color 0.2s ease-in;
}
.itemActive .itemImage {
  background-color: #262626;
}
.itemDiscount {
  background: #e93d82;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  user-select: none;
  color: #ffffff;
}
.itemPrice {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #ededed;
  gap: 8px;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.2px;
  border-radius: 5px;
  background: var(--gray-dark-2, #1c1c1cb0);
  backdrop-filter: blur(10px);
  padding: 2px 4px;
  width: fit-content;
}
.itemPriceIcon {
  height: 20px;
  width: 20px;
}
.itemPriceText {
  text-transform: uppercase;
}
.itemPriceOriginal {
  text-decoration-line: line-through;
  text-transform: uppercase;
  color: #a0a0a0;
}
.itemDetails {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
}
.itemTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  flex-grow: 0;
  flex-basis: 100%;
}
.itemAuthor {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #a0a0a0;
  cursor: pointer;
  margin-top: 10px;
}
.itemAuthor:hover {
  color: #ededed;
}
.itemActive .itemAuthor {
  z-index: 150;
}
.itemBackground {
  height: calc(100% + 20px + 86px);
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  border-radius: 6px;
  background-color: #1c1c1c;
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  user-select: none;
  pointer-events: none;
  z-index: -1;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  transform-origin: center;
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  transition: opacity 0.2s ease-in;
}
.itemOverlay {
  height: calc(100% + 20px + 86px);
  height: calc(100% + 20px);
  cursor: pointer;
  width: calc(100% + 20px);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  padding-top: 0;
  z-index: 10;
  transform-origin: center;
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  transition: opacity 0.2s ease-in;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: -10px;
  left: -10px;
}
.itemActive {
  z-index: 100;
}
.itemActive .itemBackground,
.itemActive .itemOverlay {
  opacity: 1;
  transform: scale(1);
}
.itemActive .itemOverlay {
  pointer-events: auto;
}
.itemActions {
  height: 40px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
.itemAction {
  background-color: #232323;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.itemAction:first-child {
  background-color: #f5d90a;
  color: #000;
}
.itemAction > svg {
  height: 20px;
  width: 20px;
}
.itemRating {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  gap: 4px;
  color: #a0a0a0;
}
.itemRatingScore {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.itemRatingScoreIcon {
  height: 16px;
  width: 16px;
}
.itemRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1500px) {
  .itemRow {
    grid-template-columns: repeat(6, 1fr);
  }
  .itemRow > .item:nth-child(7) {
    display: none;
  }
}
@media screen and (max-width: 1250px) {
  .itemRow {
    grid-template-columns: repeat(5, 1fr);
  }
  .itemRow > .item:nth-child(6) {
    display: none;
  }
}
@media screen and (max-width: 1050px) {
  .itemRow {
    grid-template-columns: repeat(4, 1fr);
  }
  .itemRow > .item:nth-child(5) {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .itemRow {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, fit-content);
    row-gap: 20px;
  }
  .itemRow > .item:nth-child(5),
  .itemRow > .item:nth-child(6) {
    display: flex;
  }
}
@media screen and (max-width: 550px) {
  .itemRow {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, fit-content);
    row-gap: 20px;
  }
}
@media screen and (max-width: 400px) {
  .itemRow {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 20px;
  }
  .itemRow > .item:nth-child(7) {
    display: flex;
  }
}
.itemGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 30px;
  row-gap: 20px;
  width: 100%;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-template-rows: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  height: 144px;
  /*position: sticky;
  top: 72px;*/
  width: 100%;
  padding: 0 var(--gutter);
}
.headerSticky {
  position: sticky;
  top: var(--nav-offset);
  z-index: 200;
  background-color: #161616;
}
.header > h1 {
  font-size: 56px;
  line-height: 62px;
  color: #ededed;
}
.headerTools {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
}
.categoryNav {
  margin: 0 var(--gutter);
  display: grid;
  /* detirmine amount of columns automatically, minimum width 125px, maximum width 155px */
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}
.categoryNavLink {
  aspect-ratio: 155/124;
  height: auto;
  background: #1c1c1c;
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.2;
  position: relative;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;
  color: #ededed;
  transition: all 0.1s ease-in-out;
}
.categoryNavLink:hover {
  background: #2c2c2c;
}
.categoryNavLink > svg {
  color: #a0a0a0;
  height: 48px;
  width: 48px;
  margin-bottom: 7.5%;
}
.categoryNavLink:hover > svg {
  color: #ededed;
}
.group {
  width: calc(100% - var(--gutter-double));
  margin: 0 var(--gutter);
  padding-bottom: 140px;
  padding-bottom: 32px;
  margin-top: 63px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}
.groupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 36px;
  width: 100%;
}
.groupTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #ededed;
}
.groupLink {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #a0a0a0;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
}
.groupLink::after {
  height: 2px;
  width: 100%;
  content: '';
  position: absolute;
  bottom: -12px;
  left: 0;
  background-color: #a0a0a0;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}
.groupLink:hover {
  color: #ededed;
}
.groupLink:hover::after {
  opacity: 1;
}
.groupLink:active {
  color: #fff;
}
.groupLink:active::after {
  opacity: 1;
  background-color: #fff;
}

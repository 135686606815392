.root {
  display: flex;
  position: relative;
  height: fit-content;
  border-radius: 6px;
  border: 2px solid var(--gray-dark-6, #343434);
  background: var(--gray-dark-4, #282828);
  color: #ededed;
  flex-flow: row wrap;
  padding: 6px 8px;
  transition: all 0.2s ease-in-out;
  column-gap: 6px;
  row-gap: 6px;
}
.root:not(.rootDisabled):not(:has(.input:focus)):hover {
  border-color: #505050;
}
.root:not(.rootDisabled):has(.input:focus) {
  background: #282828;
  border: 2px solid #005d85;
}
.rootDisabled {
  opacity: 0.7;
  border-color: transparent;
}
.tag {
  display: flex;
  flex-flow: row nowrap;
  height: 24px;
  background-color: var(--tag-bg, #eee);
  color: var(--tag-color, rgba(0, 0, 0, 0.87));
  gap: 4px;
  padding: 4px 6px;
  position: relative;
  border-radius: 6px;
  max-width: 100%;
}
.tagValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: inherit;
}
.tagDelete {
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tagDelete > svg {
  height: 16px;
  width: 16px;
}
.rootDisabled .tagDelete {
  cursor: not-allowed;
}
.root:not(.rootDisabled) .tag:has(.tagDelete:hover) {
  background-color: var(--tag-bg-hover, #fff);
  color: var(--tag-color-hover, rgba(0, 0, 0, 0.87));
}
.input {
  height: 24px;
  flex-grow: 1;
  min-width: 50px;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--gray-dark-12, #ededed);
}
.modal {
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: 200;
  left: -2px;
  top: calc(100% + 6px);
  background-color: #282828;
  width: calc(100% + 4px);
  border-radius: 6px;
  transform: scale(0.95);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.36, 0, 0.66, -0.56);
  transform-origin: top center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 2px;
  padding: 4px;
  color: #a0a0a0;
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}
.modalActive {
  transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.modalTop {
  top: unset;
  bottom: calc(100% + 6px);
  transform-origin: bottom center;
}
.modalActive.modalReadOnly {
  cursor: not-allowed;
}
.modalActive.modalReadOnly > * {
  opacity: 0.75;
}
.modalHasText {
  justify-content: center;
  height: 40px;
}
.modalText {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: inherit;
}
.modalItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 6px;
  gap: 8px;
  border-radius: 4px;
  flex-shrink: 0;
  cursor: pointer;
  width: 100%;
  position: relative;
}
.modalItem:disabled {
  opacity: 0.5;
  cursor: default;
}
.modal:not(.modalActive.modalReadOnly) .modalItem:not(:disabled):hover {
  background-color: #1c1c1c;
  color: #bababa;
}
.asset {
  transition: all 0.2s ease-in-out;
  height: 58px;
  justify-content: space-between;
}
.asset > svg {
  color: #fff;
  height: 20px;
  width: 20px;
}
.assetWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  gap: 8px;
}
.assetWrapper > picture {
  height: 100%;
  flex-shrink: 0;
  min-width: 0;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  background-repeat: no-repeat;
}
.assetWrapper > figure {
  display: flex;
  flex-flow: column nowrap;
  gap: 2px;
}
.assetTitle {
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  color: #dddddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  transition: all 0.2s ease-in-out;
}
.assetName {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 13px;
}
.assetTag {
  font-weight: 500;
  line-height: 12px;
  color: #c9c9c9;
  font-size: 11px;
  padding: 2px 4px;
  background-color: #494949;
  border-radius: 2px;
  transform: translateY(1px);
}
.assetIconEmpty {
  background-color: #343434;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.assetIconEmpty > svg {
  height: 18px;
  width: 18px;
  color: #a0a0a0;
}
.modal:not(.modalActive.modalReadOnly) .asset:hover .assetIconEmpty {
  background-color: #2d2d2d;
}
.modal:not(.modalActive.modalReadOnly) .asset:hover .assetTitle {
  color: #fff;
}

body {
  margin: 0;
  font-family: 'Inter', serif;
  font-style: normal;
  color: white;
  transition: background 0.5s;
  overscroll-behavior-y: none;
  background-color: #161616;
  overscroll-behavior-x: none;
}
body.animated-router-in-transition {
}

#root > .container {
  margin-top: var(--nav-offset);
  //  height: 100%;
  //
  //  overflow-y: auto;
}

* {
  line-height: 1;
}

.animated-router-forward-appear-active,
.animated-router-forward-enter-active,
.animated-router-forward-exit-active,
.animated-router-backward-appear-active,
.animated-router-backward-enter-active,
.animated-router-backward-exit-active {
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease;
}
.animated-router-forward-appear,
.animated-router-forward-enter {
  transform: translate(25%, 0);
  opacity: 0;
}
.animated-router-forward-appear-active,
.animated-router-forward-enter-active {
  transform: translate(0, 0);
  opacity: 1;
}
.animated-router-forward-exit {
  transform: translate(0, 0);
  opacity: 1;
}
.animated-router-forward-exit-active {
  transform: translate(-25%, 0);
  opacity: 0;
}
.animated-router-backward-appear,
.animated-router-backward-enter {
  transform: translate(-25%, 0);
  opacity: 0;
}
.animated-router-backward-appear-active,
.animated-router-backward-enter-active {
  transform: translate(0, 0);
  opacity: 1;
}
.animated-router-backward-exit {
  transform: translate(0, 0);
  opacity: 1;
}
.animated-router-backward-exit-active {
  transform: translate(25%, 0);
  opacity: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
*,
*:active,
*:focus {
  outline: none;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: none;
  text-align: inherit;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
}
:root {
  --yellow: #f5d90a;
  --lime: #87be22;
  --sky: #68ddfd;
  --grass: #63c174;
  --darkGrass: #16301d;
  --tomato: #e54d2e;
  --tomato-hover: #ec5e41;

  --gutter: 32px;
  --gutter-half: 16px;
  --gutter-double: 64px;
  color-scheme: dark;
  
  --nav-offset: 72px;
}

/* 1600px + gutter * 2 */
@media screen and (min-width: 1664px) {
  :root {
    --gutter-double: calc(100vw - 1600px);
    --gutter: calc(var(--gutter-double) / 2);
    --gutter-half: calc(var(--gutter) / 2);
  }
}

.mainLoading {
  height: calc(100vh - 72px - 144px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
}

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* gradient */
.yt-lite::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: #f00;
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

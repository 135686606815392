.root:has(.rootLoader),
.root:has(.rootError) {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.arrow {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ededed;
  cursor: pointer;
  margin-left: 4px;
  transition: all 0.2s ease-in;
  opacity: 0.5;
}
.arrow > svg {
  transform-origin: center;
  transform: rotate(180deg);
  height: 24px;
  width: 24px;
}
.arrow:hover {
  opacity: 1;
}
.preview {
  max-width: calc(320px - 6px);
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #1c1c1c;
  box-shadow: 0 0 0 1px inset #282828;
  user-select: none;
  border-radius: 6px;
  margin-top: 12px;
}
.preview > picture {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 100%;
}
.defaultPreview {
  height: 15%;
  width: 15%;
  max-width: 70px;
  max-height: 70px;
  min-width: 30px;
  min-height: 30px;
  color: #a0a0a0;
}
.actions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

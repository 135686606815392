.root {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
}
.rootActive {
  z-index: 50;
}
.background {
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  background: #1c1c1c;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  position: absolute;
  top: -10px;
  left: -10px;
  user-select: none;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.rootActive .background {
  opacity: 1;
}
.icon {
  aspect-ratio: 316/166;
  height: auto;
  width: 100%;
  background-color: #1c1c1c;
  border-radius: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultIcon {
  height: 36px;
  width: 36px;
  color: #a0a0a0;
  user-select: none;
}
.rootActive .icon {
  background-color: transparent;
}
.icon:has(.defaultIcon) {
  transition: all 0.3s ease;
}
.rootActive .icon:has(.defaultIcon) {
  background-color: #262626;
}
.details {
  margin-top: 20px;
  padding: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: 10px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ededed;
}
.visibility {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #87be22;
}
.visibilityPrivate {
  color: #a0a0a0;
}
.wrapper {
  position: absolute;
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  top: -10px;
  left: -10px;
  border-radius: 6px;
  z-index: 10;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.rootActive .wrapper {
  pointer-events: auto;
  opacity: 1;
}
.ctaButton {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #232323;
  border-radius: 6px;
  user-select: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.ctaButton > svg {
  color: #ededed;
  height: 20px;
  width: 20px;
}
.ctaButton:hover {
  background-color: #282828;
}
.menu {
  max-width: calc(100% - 20px - 20px);
  min-width: 220px;
  width: fit-content;
  position: absolute;
  top: calc(100% - 16px);
  right: 20px;
  background-color: #282828;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  transform-origin: top right;
  transform: scale(0.8);
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.36, 0, 0.66, -0.56);
  display: flex;
  flex-flow: column nowrap;
  padding: 6px;
  gap: 2px;
}
.rootActive .menuActive {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.menuButton {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  height: calc(20px + 16px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #ededed;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
}
.menuButton:hover {
  background-color: #1c1c1c;
}
.menuButton > svg {
  height: 20px;
  width: 20px;
}
.menuDivider {
  height: 1px;
  width: calc(100% - 20px);
  background-color: #3e3e3e;
  margin: 4px;
  user-select: none;
}
.menuButtonDestructive {
  color: #f16a50;
}
.menuButtonDestructive > svg {
  color: #d4583b;
}
.gridRoot {
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  width: inherit;
  position: relative;
}

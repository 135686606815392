.root {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s cubic-bezier(0.36, 0, 0.66, -0.56);
  will-change: background-color;
}
.rootActive {
  pointer-events: auto;
  user-select: auto;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 40px;
  width: 600px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  box-shadow: 0px 4px 72px 0px rgba(0, 0, 0, 0.6);
  overflow-y: visible;
  background: var(--gray-dark-2, #1c1c1c);
  border-radius: 10px;
  border-radius: 0.5rem;
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.36, 0, 0.66, -0.56);
  transform-origin: center center;
  position: relative;
  gap: 16px;
  will-change: transform, opacity;
}
.rootActive .modal {
  transition: all 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: scale(1);
  opacity: 1;
}
.modalTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}
.modalDescription {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.modalActions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  height: 40px;
  width: 100%;
  position: relative;
}
.modalAction {
  padding: 0;
}
.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 32px;
  width: 32px;
  z-index: 10;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--gray-dark-3, #232323);
  box-shadow: inset 0 0 0 1px var(--gray-dark-5, #2e2e2e);
  color: #ededed;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  transform: scale(1);
  cursor: pointer;
}
.closeButton:hover {
  transform: scale(1.1);
}
.closeButton:active {
  transform: scale(0.9);
  transition: none;
}
.closeButton > svg {
  height: 18px;
  width: 18px;
}

body .sky {
  --color: #0c1820;
  --background: #68ddfd;
  --background-hover: #8ae8ff;
}
body .sky:disabled,
body .sky:global(.disabled) {
  opacity: 0.4;
}
body .yellow {
  --color: #1c1500;
  --background: #f5d90a;
  --background-hover: #ffef5c;
}
body .yellow:disabled,
body .yellow:global(.disabled) {
  opacity: 0.5;
}
body .dark {
  --color: #ededed;
  --background: #232323;
  --background-hover: #282828;
}
body .invisible {
  --color: #a0a0a0;
  --background: transparent;
  --background-hover: #282828;
  --color-hover: #ededed;
}
body .tomato {
  --color: #e07258;
  --background: #371a15;
  --background-hover: #431d16;
}
body .yellow-dark {
  --color: #f0da4a;
  --background: #2a2105;
  --background-hover: #332907;
}
body .link {
  --color: #eee;
  --background: transparent;
  --background-hover: transparent;
  --color-hover: #ccc;
  padding: 0;
  width: fit-content;
}
body .red {
  --color: #fbefec;
  --background: #d4583b;
  --background-hover: #db674b;
}

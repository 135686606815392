.root {
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  height: 40px;
  user-select: none;
  --color: #161616;
  --background: #ededed;
  --color-hover: var(--color);
  --background-hover: #fff;
  background-color: var(--background);
  color: var(--color);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.root svg {
  height: 20px;
  width: 20px;
}
.root:not(:disabled):not(:global(.disabled)):hover {
  background-color: var(--background-hover);
  color: var(--color-hover);
}
.root:disabled,
.root:global(.disabled) {
  opacity: 0.75;
  cursor: not-allowed;
}
.rootPad {
  padding: 0 10px;
}

.chart {
  width: 100%;
  padding: 32px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  background-color: #1c1c1c;
  border-radius: 8px;
  margin-top: 24px;
  gap: 16px;
  margin-bottom: 52px;
}
.chartImage {
  aspect-ratio: 992/192;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  user-select: none;
  margin-top: 6px;
}
.chartImage > picture {
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: block;
}
.chartImageBlur > picture {
  filter: blur(15px) brightness(0.5);
  opacity: 0.8;
}
.chartOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 3px;
  font-size: 16px;
  line-height: 18px;
  color: #e07358;
}
.chartOverlay > svg {
  height: 32px;
  width: 32px;
}
.chartTitle {
  font-weight: 500;
  line-height: 20px;
  font-size: 20px;
}
.chartAmount {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 12px;
  color: #ededed;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}
.chartAmount > svg {
  width: 28px;
  height: 28px;
}
.chartLabel {
  margin-top: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-size: 12px;
  color: #a0a0a0;
}
.tableUser {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.tableUser > figure {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  min-width: 24px;
  background-color: #333333;
  display: block;
  background-size: cover;
  background-position: center center;
  flex-grow: 0;
  flex-shrink: 0;
  background-repeat: no-repeat;
}
.tableAmount {
  color: rgb(165, 211, 77);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  gap: 8px;
}
.tableAmount:last-child > svg {
  height: 20px;
  width: 20px;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

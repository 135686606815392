.root {
  display: grid;
  grid-template-rows: 72px 1fr;
  width: 100%;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #161616;
  z-index: 1000;
  /*animation: rootEnter 0.2s ease-in;*/
}
@keyframes rootEnter {
  from {
    opacity: 0;
    transform: translateY(6px);
  }
}
.header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--gutter);
  position: relative;
  border-bottom: 1px solid #232323;
}
.logo {
  width: 126px;
  height: 22px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/logo_long.svg);
}
.actions {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
.closeWrapper {
  height: 100%;
  border-left: 1px solid #232323;
  margin-left: 16px;
  padding-left: 32px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}
.closeButton {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  color: #a0a0a0;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}
.closeButton > svg {
  height: 20px;
  width: 20px;
}
.closeButton:not(:disabled):hover {
  background-color: #282828;
  color: #ededed;
}
.closeButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.root {
  --entry-offset: 12px;
}
.root:global(.animated-router-forward-appear-active),
.root:global(.animated-router-forward-enter-active),
.root:global(.animated-router-forward-exit-active),
.root:global(.animated-router-backward-appear-active),
.root:global(.animated-router-backward-enter-active),
.root:global(.animated-router-backward-exit-active) {
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease-in;
}
.root:global(.animated-router-forward-enter),
.root:global(.animated-router-backward-enter) {
  transform: translate(0, var(--entry-offset));
  opacity: 0;
}
.root:global(.animated-router-forward-enter-active),
.root:global(.animated-router-backward-enter-active) {
  transform: translate(0, 0);
  opacity: 1;
}
.root:global(.animated-router-forward-exit),
.root:global(.animated-router-backward-exit) {
  transform: translate(0, 0);
  opacity: 1;
}
.root:global(.animated-router-forward-exit-active),
.root:global(.animated-router-backward-exit-active) {
  z-index: 1000;
  transform: translate(0, var(--entry-offset));
  opacity: 0;
  transition: transform 500ms ease-in, opacity 400ms ease-in !important;
}
/*
.root:global(.animated-router-forward-exit-active) {
  transition-duration: 200ms;
}
.root:global(.animated-router-forward-appear),
.root:global(.animated-router-forward-enter) {
  transform: translate(0, var(--entry-offset));
  opacity: 0;
}
.root:global(.animated-router-forward-appear-active),
.root:global(.animated-router-forward-enter-active) {
  transform: translate(0, 0);
  opacity: 1;
}
.root:global(.animated-router-forward-exit) {
  transform: translate(0, 0);
  opacity: 1;
}
.root:global(.animated-router-forward-exit-active) {
  transform: translate(0, var(--exit-offset));
  opacity: 0;
}
.root:global(.animated-router-backward-appear),
.root:global(.animated-router-backward-enter) {
  transform: translate(0, var(--exit-offset));
  opacity: 0;
}
.root:global(.animated-router-backward-appear-active),
.root:global(.animated-router-backward-enter-active) {
  transform: translate(0, 0);
  opacity: 1;
}
.root:global(.animated-router-backward-exit) {
  transform: translate(0, 0);
  opacity: 1;
}
.root:global(.animated-router-backward-exit-active) {
  transform: translate(0, var(--entry-offset));
  opacity: 0;
}
*/

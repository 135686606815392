.root {
  width: 100%;
  padding: 40px var(--gutter);
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.title {
  font-size: 56px;
  font-weight: 900;
  line-height: 56px;
}
.header {
  margin-top: 48px;
  border-radius: 10px;
  background: var(--gray-dark-3, #232323);
  padding: 32px;
  margin-bottom: 56px;
}
.headerWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}
.headerTitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.headerStat {
  gap: 12px;
}
.headerStat > svg {
  height: 28px;
  width: 28px;
}
.headerSubtitle {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0.5;
}
.headerActions {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  gap: 12px;
}
.headerActionsSubtitle {
  color: #a0a0a0;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  /*margin-top: 14px;*/
}
.statWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 290px 7fr;
  gap: 20px;
  margin-top: 32px;
  position: relative;
}
.stats {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  position: relative;
  max-width: 350px;
}
.stat {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 20px;
  gap: 12px;
  border-radius: 10px;
  background: var(--gray-dark-3, #232323);
}
.statSubtitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.statTitle {
  gap: 12px;
}
.statTitle > svg {
  height: 28px;
  width: 28px;
}
.statFigure {
  position: relative;
  height: 100%;
  display: grid;
  gap: 20px;
  grid-template-rows: 1fr 14px;
}
.statFigureImage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.statFigureImage > picture {
  height: 100%;
  width: 100%;
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.statFigureBlur > picture {
  filter: blur(15px) brightness(0.5);
  opacity: 0.8;
}
.statFigureOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 3px;
  font-size: 16px;
  line-height: 18px;
  color: #e07358;
}
.statFigureOverlay > svg {
  height: 32px;
  width: 32px;
}
.statFigureCaption {
  height: 14px;
}
.statFigureCaption > figcaption {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.tableHeader {
  margin-bottom: 32px;
  margin-top: 60px;
}
.priceCell {
  gap: 8px;
  flex-flow: row nowrap;
  align-items: center;
}
.priceCell > svg {
  height: 20px;
  width: 20px;
}
.infoCell {
  gap: 8px;
}
.infoIcon {
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  border-radius: 3px;
}
.infoCellImage {
  height: 40px;
  flex-shrink: 0;
  width: 40px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.label {
  padding: 6px 10px;
  display: inline-flex;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  height: calc(100vh - 72px);
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

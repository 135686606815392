.item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
}
.itemActive {
  z-index: 50;
}
.itemIcon {
  aspect-ratio: 1/1;
  height: auto;
  width: 100%;
  background-color: #1c1c1c;
  border-radius: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  user-select: none;
  display: flex;
  flex-shrink: 0;
  position: relative;
}
.defaultIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 15%;
  width: 15%;
  max-width: 70px;
  max-height: 70px;
  min-width: 30px;
  min-height: 30px;
  color: #a0a0a0;
}
.itemIcon/*:has(.defaultIcon) */ {
  /*transition: background-color 0.3s ease;*/
  transition: background-color 0.2s ease-in;
}
.itemActive .itemIcon/*:has(.defaultIcon) */ {
  background-color: #262626;
}
.itemSize {
  /*top: 10px;
  right: 10px;
  position: absolute;
  color: var(--gray-dark-11, #a0a0a0);
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;*/
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--gray-dark-11, #a0a0a0);
  gap: 8px;
  user-select: none;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  border-radius: 5px;
  background: var(--gray-dark-2, #1c1c1ca0);
  backdrop-filter: blur(10px);
  padding: 2px 4px;
  width: fit-content;
  top: 10px;
  right: 10px;
  position: absolute;
}
.itemDetails {
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;
}
.itemTitle {
  color: #fff;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.itemStatus,
.itemAuthor {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.itemAuthor {
  color: var(--gray-dark-11, #a0a0a0);
  cursor: pointer;
  position: relative;
  z-index: 20;
}
.itemAuthor:hover {
  color: var(--gray-dark-10, #b0b0b0);
}
.itemBackground,
.itemOverlay {
  width: calc(100% + 20px);
  top: -10px;
  left: -10px;
  height: calc(100% + 20px + 40px + 20px /* + 10px*/);
  height: calc(100% + 20px);
  position: absolute;
  border-radius: 6px;
  opacity: 0;
  /*transform: scale(0.9);*/
  transform-origin: center;
  user-select: none;
  pointer-events: none;
  /*transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);*/
  transition: all 0.2s ease-in;
}
.itemBackground {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  display: block;
  z-index: -1;
  background-color: #1c1c1c;
}
.itemOverlay {
  display: flex;
  z-index: 10;
  cursor: pointer;
}
.itemActive .itemBackground,
.itemActive .itemOverlay {
  opacity: 1;
  transform: scale(1);
}
.itemActive .itemOverlay {
  pointer-events: auto;
}
.itemActions {
  width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.itemMenu {
  width: calc(100% - 40px);
  top: calc(100% - 10px);
  left: 20px;
  position: absolute;
  background: #282828;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 100;
  opacity: 0;
  transform-origin: top right;
  transform: scale(0.8);
  transition: all 0.25s cubic-bezier(0.36, 0, 0.66, -0.56);
  pointer-events: none;
  user-select: none;
  padding: 6px;
  gap: 2px;
}
.itemMenuActive {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
  transition: all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.itemMenuButton {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  height: calc(20px + 16px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #ededed;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
}
.itemMenuButton:hover {
  background-color: #1c1c1c;
}
.itemMenuButton > svg {
  height: 20px;
  width: 20px;
}
.itemMenuDivider {
  height: 1px;
  width: calc(100% - 20px);
  background-color: #3e3e3e;
  margin: 4px;
  user-select: none;
}
.itemMenuButtonDestructive {
  color: #f16a50;
}
.itemMenuButtonDestructive > svg {
  color: #d4583b;
}
.grid {
  display: grid;
  column-gap: 30px;
  row-gap: 20px;
  width: 100%;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: auto;
}

.root {
  width: 100%;
  padding: 40px var(--gutter);
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding-bottom: 32px;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  height: calc(100vh - 72px);
  align-items: center;
  justify-content: center;
}
.rootError {
  color: #ff0000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.title {
  color: var(--gray-dark-12, #ededed);
  font-size: 56px;
  font-weight: 900;
  line-height: 56px; /* 100% */
}
.subtitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  max-width: 600px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-top: 16px;
}
.header {
  margin-top: 48px;
  padding: 32px;
  gap: 28px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
}
.header::before {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background: #1c1c1c;
  background-image: url(../../assets/images/welcome/lock.png);
  background-size: contain;
  background-position: right center;
  content: '';
  position: absolute;
  pointer-events: none;
  z-index: -1;
  background-repeat: no-repeat;
}
.headerInfo {
  gap: 24px;
}
.headerIcon {
  height: 88px;
  width: 88px;
  background: var(--sky-dark-4, #082d41);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.headerIcon > svg {
  color: #2ec8ee;
  height: 50px;
  width: 50px;
}
.headerText {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}
.headerText > h2 {
  color: var(--gray-dark-12, #ededed);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px; /* 100% */
}
.headerText > p {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.headerTools {
  gap: 8px;
}
.headerInput {
  width: 380px;
}
.sectionTitle {
  margin-top: 48px;
  color: var(--gray-dark-12, #ededed);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px; /* 100% */
}
.section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  width: 100%;
  position: relative;
  gap: 32px;
  margin-top: 28px;
}
.card {
  border-radius: 12px;
  background: var(--gray-dark-2, #1c1c1c);
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  padding: 24px;
}
.cardNumber {
  border-radius: 6px;
  background: var(--sky-dark-4, #082d41);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sky-dark-11, #2ec8ee);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px; /* 100% */
  width: 48px;
  height: 48px;
  user-select: none;
}
.section2 .cardNumber {
  background: var(--purple-dark-4, #3a1e48);
  color: var(--purple-dark-11, #bf7af0);
}
.cardImage {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  aspect-ratio: 380/240;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 24px 0;
}
.cardImage[data-image='install'] {
  background-image: url(../../assets/images/welcome/install.png);
}
.cardImage[data-image='login'] {
  background-image: url(../../assets/images/welcome/login.png);
}
.cardImage[data-image='play'] {
  background-image: url(../../assets/images/welcome/play.png);
}
.cardImage[data-image='unreal'] {
  background-image: url(../../assets/images/welcome/unreal.png);
}
.cardImage[data-image='discord'] {
  background-image: url(../../assets/images/welcome/discord.png);
}
.cardImage[data-image='studio'] {
  background-image: url(../../assets/images/welcome/studio.png);
}
.cardTitle {
  color: var(--gray-dark-12, #ededed);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px; /* 100% */
}
.cardDescription {
  color: var(--gray-dark-11, #a0a0a0);
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.cardDescription > a {
  color: var(--gray-dark-12, #ededed);
  font-weight: 600;
  text-decoration-line: underline;
}

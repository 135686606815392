.root {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding: 40px var(--gutter);
  width: 100%;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  align-items: center;
  justify-content: center;
  height: calc(100vh - 72px);
}
.title {
  color: var(--gray-dark-12, #ededed);
  font-size: 56px;
  font-weight: 900;
  line-height: 56px;
  align-self: flex-start;
}
.header {
  width: 100%;
  border-radius: 12px;
  height: 164px;
  padding: 32px;
  position: relative;
  margin-top: 48px;
  z-index: 1;
}
.header::before {
  right: 0;
  top: 0;
  position: absolute;
  content: '';
  z-index: -1;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 12px 12px 12px 12px;
  background-color: var(--gray-dark-2, #1c1c1c);
}

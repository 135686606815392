.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  border-radius: 6px;
  border: 2px solid var(--gray-dark-6, #343434);
  background: var(--gray-dark-4, #282828);
  height: 40px;
  transition: all 0.2s ease-in-out;
  color: #ededed;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.rootDisabled {
  opacity: 0.75;
  border-color: transparent;
}
.root:not(.rootDisabled):not(:has(.optionsActive)):hover {
  border-color: #505050;
}
.root:not(.rootDisbled):has(.optionsActive) {
  background: #282828;
  border: 2px solid #005d85;
}
.toggle {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
}
.toggleIcon {
  height: 20px;
  width: 20px;
  color: inherit;
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.toggleIcon > svg {
  height: 20px;
  width: 20px;
}
.rootDisabled .toggle {
  cursor: not-allowed;
}
.toggleLoader {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-4px, -4px);
}
.toggleContent,
.toggleInfo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}
.toggleContent {
  gap: 8px;
}
.toggleContent > svg {
  height: 20px;
  width: 20px;
}
.toggleInfo {
  color: #757575;
}
.toggleIcon {
  user-select: none;
  height: 20px;
  width: 20px;
  color: #ededed;
}
.options {
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: 200;
  left: -2px;
  top: calc(36px + 6px);
  background-color: #282828;
  width: calc(100% + 4px);
  border-radius: 6px;
  transform: scale(0.95);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.36, 0, 0.66, -0.56);
  transform-origin: top center;
  display: grid;
  grid-template-rows: repeat(auto-fill, 34px);
  gap: 2px;
  padding: 4px;
  color: #a0a0a0;
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}
.optionsActive {
  transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.rootTop .options {
  top: unset;
  bottom: calc(36px + 6px);
  transform-origin: bottom center;
}
.option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.optionActive {
  color: #ededed;
  cursor: default;
}
.option:not(.optionActive):hover {
  background-color: #1c1c1c;
  color: #bababa;
}
.option > svg {
  height: 16px;
  width: 16px;
}
.option > span {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
.option > span > svg {
  height: 20px;
  width: 20px;
}

.root {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}
div:has(.root) {
  margin-top: 0 !important;
}



.loader {
  height: 115px;
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #082d41;
  margin-bottom: 16px;
}
.title {
  color: var(--gray-dark-12, #ededed);
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
}
.subtitle {
  margin-top: 16px;
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.subtitle:not(:first-of-type) {
  margin-top: 2px;
}

.root {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.root:has(.rootLoader),
.root:has(.rootError) {
  align-items: center;
  justify-content: center;
  height: calc(100vh - 72px);
}
.header {
  height: 144px;
  padding: 0 var(--gutter);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.title {
  color: var(--gray-dark-12, #ededed);
  font-size: 56px;
  font-weight: 900;
  line-height: 56px;
}
.wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 0 var(--gutter);
  padding-bottom: 32px;
  position: relative;
}
.subtitle {
  color: var(--gray-dark-11, #a0a0a0);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 150% */
}
.table {
  margin-top: 32px;
}
.accessToken {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.1);
  height: 44px;
  flex-shrink: 0;
  padding: 0 13px;
}
.accessToken > figcaption {
  color: #eee;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.accessToken > button {
  position: absolute;
  top: 2px;
  right: 2px;
}
.subtitle > a {
  cursor: pointer;
  color: #d64c81;
}
.subtitle > a:hover {
  text-decoration: underline;
}
.key {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 6px;
  color: #a1a1a1;
  font-weight: 400;
}
.key > svg {
  height: 18px;
  width: 18px;
}
